import React, { Component } from "react";
import OurFleetComponent from "../../components/OurFleetComponent";
import { NavLink} from 'react-router-dom';

import {Helmet} from "react-helmet";
import PickupServiceSteps from "../../components/PickupServiceSteps";

export default class AirportTransfer extends Component {
    render() {
        return (
            <div className="LandingPage content">

                <Helmet>
                    <title>Company and Group Transfer in Hungary</title>
                    <meta
                        name="description"
                        content="Bus rentals and transfer services for corporate groups. Private transfer for 1 to 50 people with a comfortable minibus or coach. Comfortable, air-conditioned vehicles, in different categories. School trips. Special occasions. Private coach hire."
                    />

                    <meta
                        name="keywords"
                        content="Airport Transfers Hungary, Group Transfer Hungary,  group transportation Hungary, comfortable minibuses hungary, coaches hungary, transport large groups hungary, touristic groups hungary, comfortable minibus or coach hungary, company transfer services hungary, bus transfer hungary, book a transfer from the airport hungary, Corporate travel hungary, Corporate groups hungary, school trips hungary, weekend excursions hungary, special occasions hungary, corporate conference hungary"
                    />
                </Helmet>

                <div id="LandingPage__content">
                    <div class="mt-4 mb-5"></div>

                    <div className="container">
                        <h1 className="h2 mb-4"><i class="fa-duotone fa-van-shuttle me-2 text-primary"></i> Company and Group Transfer in Hungary</h1>
                        <h2 className="h3">Private Coach Rental</h2>

                        <p>
                            Bus rentals and transfer services for corporate groups.<br />
                            Private transfer for 1 to 50 people with a comfortable minibus or coach. Comfortable, air-conditioned vehicles, in different categories. School trips. Special occasions. Private coach hire.
                        </p>

                        <div className="row mt-5">
                            <div className="col-12 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title h4">Meet and Greet Airport Service</h3>
                                        <p className="card-text">
                                            Private Transfer. Meet at and greet at the gate. Sign labeled with your name.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title h4">Save Money & Time</h3>
                                        <p className="card-text">
                                            Fixed rates. No booking & hidden fees. No credit card surcharges.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 mt-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title h4">Safe & Clean</h3>
                                        <p className="card-text">
                                            European Union standards. Verified drivers. Cleaned after every use. Safe.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 mt-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title h4">Designed for Groups</h3>
                                        <p className="card-text">
                                            Rent by day or by destination. Fast and hassle free booking. Money back guarantee.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <PickupServiceSteps />

                        <div className="pt-5 pb-5 mt-5 text-center">
                            <p className="h5">
                                <b>Risk free booking. Cancel up to 24 hours before your travel date.</b>
                            </p>
                            <NavLink to="/booking" className="btn btn-lg btn-primary mt-5">Book Now</NavLink>
                        </div>

                    </div>


                    <div className="LandingPage__our__fleet pt-5 pb-5 bg-gray">
                        <div className="container">
                            <OurFleetComponent />
                        </div>
                    </div>
                    
                            
                </div>
            </div>
        );
    }
}