import React, { Component } from "react";
import SearchSectionComponent from "../../components/SearchSectionComponent";
import HelpSectionComponent from "../../components/HelpSectionComponent";

import {Helmet} from "react-helmet";

export default class AirportTransfer extends Component {
    render() {
        return (
            <div className="LandingPage content">
                <Helmet>
                    <title>404 - Not found</title>
                    <meta
                        name="description"
                        content="This page can not be found"
                    />

                    <meta
                        name="keywords"
                        content="404"
                    />

                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>

                <SearchSectionComponent />

                <div id="LandingPage__content">

                    <div className="container pt-5 pb-5">
                        <h1 class="h2">We can't find what you are looking for</h1>
                        <p>We are very sorry, but the page you are looking for doesn't seem to exist anymore.</p>
                    </div>

                </div>

                <HelpSectionComponent />
            </div>
        );
    }
}