import React from "react";

import NoResultsComponent from '../../components/BookingPageNoResultsComponent';

export const BookingPageStep1Template = ({common, chooseCategory, step1, requestRoute, handleChange, noResults}) => (
    <div className="BookingPage__step1">

        {step1.routes.length === 0 && 
            <NoResultsComponent 
                onSubmit={requestRoute}
                handleChange={handleChange}
                noResults={noResults}
            />
        }

        {step1.routes.map((item, key) =>
            <div className="BookingPage__box mb-5">
                <div className="BookingPage__box__content">
                        
                    <div className="row">
                    
                        <div className="col-md-4 col-12 BookingPage__step1__category">
                            <img className="BookingPage__box__content__category_image" src={item.category.image} alt={item.category.name} />
                            <p className="BookingPage__box__content__category font-weight-bold h2 text-md-center mt-2 mb-2 mt-md-0 mb-md-0">
                                {item.category.name}
                            </p>
                        </div>

                        <div className="col-md-3 col-12 offset-md-1 BookingPage__step1__details">
                            <p>
                                <i class="fas fa-users"></i> {item.category.maximumNumberOfPassangers}
                                <i class="fas fa-suitcase-rolling ms-3"></i> {item.category.maximumNumberOfLuggages}
                            </p>

                            <p>
                                <i class="fas fa-check-circle text-primary"></i> Free Waiting Time: 60 minutes at airports, 15 minutes everywhere else
                            </p>
                        </div>

                        <div className="col-md-3 col-12 offset-md-1 BookingPage__step1__price text-md-center">
                            <p>
                                <span className="h3 font-weight-bold">{item.priceEur} EUR</span><br /> 
                                {common.return_journey_checkbox ? <small>per way</small> : null}
                            </p>
                            <button onClick={() => chooseCategory(item.category.name, item.category.image, item.priceEur, item.category.maximumNumberOfPassangers, item.category.maximumNumberOfLuggages)} className="btn btn-primary btn-lg mt-2">Select</button>
                        </div>
                    </div>

                </div>
            </div>

        )}
        
    </div>
);