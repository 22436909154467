import React from "react";

import BookingPageSummaryComponent from '../../components/BookingPageSummaryComponent';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

//import {Typeahead} from 'react-bootstrap-typeahead';
import Select from 'react-select'
import {phoneExtensions, countries, times} from '../../constants';

import DatePicker from "react-datepicker";
import moment from "moment";

import { NavLink} from 'react-router-dom';

export function BookingPageStep2Template ({
    step2, 
    goToStep, 
    handleChange, 
    common, 
    preview, 
    sameAsAboveBillingName, 
    childSeat, 
    childSeatIncrease, 
    childSeatDecrease,
    adults,
    errors,
    acceptTerms,
    validateDiscountCode
})  {

    return(
        <div className="BookingPage__box BookingPage__step2">
            <div className="BookingPage__box__content">
                <BookingPageSummaryComponent common={common} />

                    <Form noValidate onSubmit={preview}>

                        <div className="row mt-3 mb-3">
                            <div className="col-12">
                                <p className="h5 font-weight-bold">
                                    Journey
                                </p>
                            </div>
                            
                        </div>    
                    
                        <Form.Group className="row mt-3" controlId="step2.full_name">
                            <div className="col-md-3">
                                <Form.Label>Full Name</Form.Label>
                            </div>

                            <div className="col-md-9 col-12">

                                <Form.Control
                                    type="text"
                                    name="step2.full_name"
                                    placeholder="Full Name"
                                    value={step2.full_name || ''}
                                    onChange={handleChange}
                                    isInvalid={errors.full_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.full_name}
                                </Form.Control.Feedback>
                            </div>
                            
                        </Form.Group>

                        <Form.Group className="row mt-3" controlId="step2.email_address">
                            <div className="col-md-3 col-12">
                                <Form.Label>E-mail Address</Form.Label>
                            </div>

                            <div className="col-md-9 col-12">

                                <Form.Control
                                    type="text"
                                    placeholder="E-mail Address"
                                    value={step2.email_address || ''}
                                    onChange={handleChange}
                                    isInvalid={errors.email_address}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email_address}
                                </Form.Control.Feedback>
                            </div>
                            
                        </Form.Group>

                        <div className="row mt-3">

                            <div className="col-md-3 col-12 ">
                                <Form.Label for="step2.phone_number">Phone Number</Form.Label>
                            </div>

                            <Form.Group className="col-md-9 col-12">

                                <div className="row">
                                
                                    <div className="col-md-4 col-12">

                                        <Select
                                            className="form-control-select"
                                            placeholder="Phone Extension"
                                            name="step2.phone_extension_select"
                                            defaultInputValue={step2.phone_extension_select}
                                            getOptionValue={option => option.label}
                                            selected={step2.phone_extension_select}
                                            onChange={(value) => {
                                                handleChange({target: {id: "step2.phone_extension_select", value: value.label}, callAsync: () => 
                                                    handleChange({target: {id: "step2.phone_extension", value: value.label}})
                                                });
                                                
                                            }}
                                            options={phoneExtensions.sort((a, b) => a < b ? -1 : 1)}
                                        />

                                        <Form.Control
                                            type="hidden" 
                                            name="step2.phone_extension" 
                                            value={step2.phone_extension}
                                            isInvalid={errors.phone_extension}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.phone_extension}
                                        </Form.Control.Feedback>
                                    </div>

                                    <div className="col-md-8 col-12 mt-3 mt-md-0">
                                        <Form.Control
                                            type="number"
                                            name="step2.phone_number"
                                            placeholder="i.e. 1234 567891" 
                                            value={step2.phone_number || ''}
                                            onChange={handleChange}
                                            isInvalid={errors.phone_number}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.phone_number}
                                        </Form.Control.Feedback>
                                    </div>
                                </div>
                                
                            </Form.Group>
                        
                        </div>

                        <div className="form-group row mt-3">
                            <div className="col-md-3 col-12 ">
                                <label for="common.destination_from">From</label>
                            </div>
                            
                            <div className="col-md-9 col-12">
                                { common.destination_from }
                            </div>
                        </div>

                        <div className="form-group row mt-3">
                            <div className="col-md-3 col-12 ">
                                <label for="common.destination_to">To</label>
                            </div>
                            
                            <div className="col-md-9 col-12">
                                { common.destination_to }
                            </div>
                        </div>

                        <div className="row mt-3">

                            <div className="col-md-3 col-12 ">
                                <Form.Label for="step2.date_picker">Date</Form.Label>
                            </div>

                            <Form.Group className="col-md-9 col-12">

                                <div className="row">
                                
                                    <div className="col-md-7 col-12">
                                        <DatePicker
                                            className="form-control"
                                            selected={step2.date_picker}
                                            dateFormat="MMMM d, yyyy"
                                            onChange={(value) => {
                                                handleChange({target: {id: "step2.date_picker", value}, callAsync: () => {
                                                    handleChange({target: {id: "step2.date", value: moment(value).format("MMMM DD, yyyy")}})
                                                }})
                                            }}
                                            minDate={new Date()}
                                            placeholderText="Choose date"
                                            id="step2.date_picker"
                                            autoComplete="off"
                                        />

                                        <Form.Control
                                            type="hidden" 
                                            value={step2.date} 
                                            name="step2.date"
                                            isInvalid={errors.date} 
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.date}
                                        </Form.Control.Feedback>
            
                                    </div>

                                    <div className="col-md-5 col-12 mt-3 mt-md-0">

                                        <Select
                                            className="form-control-select"
                                            placeholder="Choose Time"
                                            name="step2.time_select"
                                            defaultInputValue={step2.time_select}
                                            getOptionValue={option => option.label}
                                            selected={step2.time_select}
                                            onChange={(value) => {
                                                handleChange({target: {id: "step2.time_select", value: value.label}, callAsync: () => {
                                                    handleChange({target: {id: "step2.time", value: value.label}});
                                                }});
                                                
                                            }}
                                            options={times.sort()}
                                        />

                                        <Form.Control
                                            type="hidden" 
                                            name="step2.time" 
                                            value={step2.time}
                                            isInvalid={errors.time}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.time}
                                        </Form.Control.Feedback>
                                    </div>

                                    <div className="col-12 mt-2">
                                        <small className="text-secondary">
                                            <span class="fa fa-info-circle text-primary me-2 fs-6"></span> 
                                            If your pick-up is at the airport, set the time for your plane's arrival. 
                                            We will monitor your flight for delays.
                                        </small>
                                    </div>
                                </div>
                                
                            </Form.Group>

                        
                        </div>

                        { common.return_journey_checkbox ? <div className="row mt-3">

                            <div className="col-md-3 col-12 ">
                                <Form.Label for="step2.return_date">Return Date</Form.Label>
                            </div>

                            <Form.Group className="col-md-9 col-12">

                                <div className="row">
                                
                                    <div className="col-md-7 col-12">
                                        <DatePicker
                                            className="form-control"
                                            selected={step2.return_date_picker}
                                            dateFormat="MMMM d, yyyy"
                                            onChange={(value) => {
                                                handleChange({target: {id: "step2.return_date_picker", value}, callAsync: () => {
                                                    handleChange({target: {id: "step2.return_date", value: moment(value).format("MMMM DD, yyyy")}})
                                                }})
                                            }}
                                            minDate={new Date()}
                                            placeholderText="Choose Return Date"
                                            id="step2.return_date_picker"
                                            autoComplete="off"
                                        />

                                        <Form.Control
                                            type="hidden" 
                                            value={step2.return_date} 
                                            name="step2.return_date"
                                            isInvalid={errors.return_date} 
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.return_date}
                                        </Form.Control.Feedback>
                                    </div>

                                    <div className="col-md-5 col-12 mt-3 mt-md-0">
                                        <Select
                                            className="form-control-select"
                                            placeholder="Choose Time"
                                            name="step2.return_time_select"
                                            defaultInputValue={step2.return_time_select}
                                            getOptionValue={option => option.label}
                                            selected={step2.return_time_select}
                                            onChange={(value) => {
                                                handleChange({target: {id: "step2.return_time_select", value: value.label}, callAsync: () => {
                                                    handleChange({target: {id: "step2.return_time", value: value.label}});
                                                }});
                                                
                                            }}
                                            options={times.sort()}
                                        />

                                        <Form.Control
                                            type="hidden" 
                                            name="step2.return_time" 
                                            value={step2.return_time}
                                            isInvalid={errors.return_time}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.return_time}
                                        </Form.Control.Feedback>
                                    </div>

                                    <div className="col-12 mt-2">
                                        <small className="text-secondary">
                                            <span class="fa fa-info-circle text-primary me-2 fs-6"></span> 
                                            For airport returns, please arrive at the airport at least 3 hours before your departure.
                                        </small>
                                    </div>
                                </div>
                                
                            </Form.Group>
                        
                        </div> : null}

                        <div className="row mt-3">

                            <div className="col-md-3 col-12 ">
                                <Form.Label for="step2.adults">Passengers</Form.Label>
                            </div>

                            <Form.Group className="col-md-9 col-12">

                                <div className="row gy-2">
                                
                                    <div className="col-lg-4 col-12">

                                        <Form.Control as="select" 
                                            name="step2.adults"
                                            onChange={handleChange}
                                            value={step2.adults}
                                            isInvalid={errors.adults}
                                        >
                                            <option value="">Number of adults</option>
                                            {adults().map((value) => {
                                                return <option 
                                                    key={value} 
                                                    value={value}
                                                >{value} adult{value > 1 ? "s" : ""}</option>
                                            })}
                                        </Form.Control>

                                        <Form.Control.Feedback type="invalid">
                                            {errors.adults}
                                        </Form.Control.Feedback>
                                    </div>

                                    <div className="col-lg-8 col-12">
                                        
                                        <div class="input-group">
                                            <Form.Control 
                                                placeholder="Child seat(s)"
                                                onClick={childSeat} 
                                                name="step2.child_seat"
                                                value={step2.child_seat}
                                                onChange={handleChange}
                                                disabled={step2.adults >= common.capacity}
                                                autoComplete="off"
                                                isInvalid={errors.child_seat}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.child_seat}
                                            </Form.Control.Feedback>

                                            {step2.child_seat_open ? <div className="BookingPage__step2__child_seat" id="child_seat">
                                                <div className="mt-1">
                                                    <div className="row mt-3">
                                                        <div className="col-6">
                                                            <p>
                                                                Infants Carrier<br />
                                                                <small>up to 10 kg, 6 months</small>
                                                            </p>
                                                        </div>

                                                        <div className="col-6 text-end">
                                                            <button className="btn btn-primary btn-circle" type="button" onClick={() => childSeatDecrease('infant_carrier')}>
                                                                <i class="fas fa-minus"></i>
                                                            </button>

                                                            <span className="pr-3 pl-3">
                                                                {step2.infant_carrier}
                                                            </span>

                                                            <button className="btn btn-primary btn-circle" type="button" onClick={() => childSeatIncrease('infant_carrier')}>
                                                                <i class="fas fa-plus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-3">
                                                    <div className="row mt-3">
                                                        <div className="col-6">
                                                            <p>
                                                                Convertible Seat<br />
                                                                <small>9–25 kg, 0–7 years</small>
                                                            </p>
                                                        </div>

                                                        <div className="col-6 text-end">
                                                            <button className="btn btn-primary btn-circle" type="button" onClick={() => childSeatDecrease('convertible_seat')}>
                                                                <i class="fas fa-minus"></i>
                                                            </button>

                                                            <span className="pr-3 pl-3">
                                                                {step2.convertible_seat}
                                                            </span>

                                                            <button className="btn btn-primary btn-circle" type="button" onClick={() => childSeatIncrease('convertible_seat')}>
                                                                <i class="fas fa-plus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-3 mb-1">
                                                    <div className="row mt-3">
                                                        <div className="col-6">
                                                            <p>
                                                                Booster Seat<br />
                                                                <small>22–36 kg, 6–12 years</small>
                                                            </p>
                                                        </div>

                                                        <div className="col-6 text-end">
                                                            <button className="btn btn-primary btn-circle" type="button" onClick={() => childSeatDecrease('booster_seat')}>
                                                                <i class="fas fa-minus"></i>
                                                            </button>

                                                            <span className="pr-3 pl-3">
                                                                {step2.booster_seat}
                                                            </span>

                                                            <button className="btn btn-primary btn-circle" type="button" onClick={() => childSeatIncrease('booster_seat')}>
                                                                <i class="fas fa-plus"></i>
                                                            </button>
                                                        </div>

                                                        <div className="col-12">
                                                            <p>
                                                                <small>Maximum Capacity: {common.capacity} passengers</small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null}
                                        </div>

                                    </div>
                                </div>
                                
                            </Form.Group>
                        
                        </div>

                        <Form.Group className="row mt-3">
                            <div className="col-md-3 col-12 ">
                                <Form.Label>Additional Info</Form.Label>
                            </div>

                            <div className="col-md-9 col-12">

                                <div className="input-group">
                                    <div class="input-group-text">
                                        <Form.Check
                                            name="step2.flight_number_checkbox"
                                            onClick={handleChange} 
                                            checked={step2.flight_number_checkbox}
                                        />
                                    </div>
                                    <Form.Control 
                                        name="step2.flight_number"
                                        placeholder="Flight Number(s)"
                                        onChange={handleChange}
                                        value={step2.flight_number}
                                        disabled={!step2.flight_number_checkbox}
                                    />
                                </div>

                                <Form.Control.Feedback type="invalid">
                                    {errors.flight_number}
                                </Form.Control.Feedback>
                            </div>
                            
                        </Form.Group>

                        <div className="row mt-3 mt-3 mb-3 mt-4 mt-md-0">
                            <div class="col-12"><p className="font-weight-bold h5">Billing Details</p></div>
                        </div>

                        <Form.Group className="row mt-3" controlId="step2.billing_name">
                            <div className="col-md-3 col-12 ">
                                <Form.Label>Billing Name</Form.Label>
                            </div>

                            <div className="col-md-9 col-12">

                                <Form.Control
                                    type="text"
                                    placeholder="Billing Name"
                                    value={step2.billing_name || ''}
                                    onChange={handleChange}
                                    isInvalid={errors.billing_name}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.billing_name}
                                </Form.Control.Feedback>

                                <div className="mt-3" />
                                <input 
                                    type="checkbox"
                                    className="me-2 form-check-input" 
                                    onChange={sameAsAboveBillingName} 
                                /> Same as above

                                
                            </div>

                        </Form.Group>
                        
                        <Form.Group className="row mt-3" controlId="step2.billing_address">
                            <div className="col-md-3 col-12 ">
                                <Form.Label>Address</Form.Label>
                            </div>

                            <div className="col-md-9 col-12">

                                <Form.Control
                                    type="text"
                                    placeholder="Billing Address"
                                    value={step2.billing_address || ''}
                                    onChange={handleChange}
                                    isInvalid={errors.billing_address}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.billing_address}
                                </Form.Control.Feedback>

                            </div>

                        </Form.Group>

                        <div className="row mt-3">
                            <div class="col-md-3 col-12 ">
                                <Form.Label for="step2.billing_postcode">Postcode and City</Form.Label>
                            </div>

                            <Form.Group className="col-md-4 col-12" controlId="step2.billing_postcode">
                                <Form.Control 
                                    placeholder="Postcode"
                                    onChange={handleChange}
                                    value={step2.billing_postcode}
                                    isInvalid={errors.billing_postcode}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.billing_postcode}
                                </Form.Control.Feedback>

                            </Form.Group>

                            <Form.Group className="col-md-5 col-12" controlId="step2.billing_city">
                                <Form.Control
                                    placeholder="City"
                                    onChange={handleChange}
                                    value={step2.billing_city}
                                    isInvalid={errors.billing_city}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.billing_city}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>

                        <Form.Group className="row mt-3">
                            <div className="col-md-3 col-12 ">
                                <Form.Label>Country</Form.Label>
                            </div>
                            
                            <div className="col-md-9 col-12">
                                <Select
                                    className="form-control-select"
                                    placeholder="Country"
                                    name="step2.billing_country_select"
                                    defaultInputValue={step2.billing_country_select}
                                    getOptionValue={option => option.label}
                                    selected={step2.billing_country_select}
                                    onChange={(value) => {
                                        handleChange({target: {id: "step2.billing_country_select", value: value.label}, callAsync: () => {
                                            handleChange({target: {id: "step2.billing_country", value: value.label}});
                                        }});
                                        
                                    }}
                                    options={countries.sort()}
                                />

                                <Form.Control
                                    type="hidden" 
                                    name="step2.billing_country" 
                                    value={step2.billing_country}
                                    isInvalid={errors.billing_country}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors.billing_country}
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <div className="row mt-3 mt-3 mb-3">
                            <div class="col-12"><p className="font-weight-bold h5">Anything Else?</p></div>
                        </div>

                        <Form.Group className="row mt-3" controlId="step2.comments">
                            <div className="col-md-3 col-12 ">
                                <Form.Label>Comments</Form.Label>
                            </div>
                            
                            <div className="col-md-9 col-12">
                                <Form.Control as="textarea"
                                    name="step2.comments"
                                    onChange={handleChange}
                                    value={step2.comments}
                                />
                            </div>
                        </Form.Group>

                        <Form.Group className="row mt-3" controlId="step2.discount_code">

                            <div className="col-12">
                                <button 
                                    role="link" 
                                    className="btn btn-link btn-no-padding" 
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handleChange({target: {id: "step2.have_discount_code", value: !step2.have_discount_code}});
                                    }}
                                >
                                    Did you receive a promo code?
                                </button>
                            </div>
                            
                            {step2.have_discount_code && 
                                <>
                                    <div className="col-lg-6 col-12 mt-3">
                                        <InputGroup>
                                            <Form.Control
                                                name="step2.discount_code"
                                                value={step2.discount_code}
                                                onChange={handleChange}
                                                placeholder="Discount Code"
                                            />
                                        <InputGroup.Text>
                                            <Button 
                                                variant="outline-secondary"
                                                onClick={(event) => { 
                                                    event.preventDefault(); 
                                                    validateDiscountCode();
                                                }}
                                            >
                                                Validate
                                            </Button>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </div>

                                <div className="col-lg-6 col-12">
                                    {step2.sent_discount_code === true ? step2.discount_code_response ? 
                                        <p className="text-success">Promo code validated, your discount is now applied</p> :
                                        <p className="text-danger">The promo code is invalid, please try again with another one</p>
                                    : <></>}
                                </div> 
                                </>}
                        </Form.Group>

                        <Form.Group className="mt-4 form-check" controlId="step2.termsAndConditions">

                            <hr />

                            <input 
                                type="checkbox"
                                className="mr-2 form-check-input" 
                                onChange={acceptTerms}
                            /> 
                            <span className="form-check-label">
                            I accept <NavLink to="/terms-and-conditions" rel="noopener noreferrer" target="_blank">Terms and Conditions</NavLink> and <NavLink rel="noopener noreferrer" to="/privacy-policy" target="_blank">Privacy Policy</NavLink>
                            </span>
                        </Form.Group>

                        <div className="mt-5"></div>
                        
                        <div className="row mt-3">
                            <div className="col-3">
                                <button type="button" onClick={() => goToStep(1)} className="btn btn-dark">Back</button>
                            </div>
                            
                            <div className="col-9 text-end">
                                <button type="submit" className="btn btn-primary btn-lg" disabled={!step2.terms_accepted}>Preview</button>
                            </div>
                        </div>
                    </Form>

            </div>
        </div>
    );  
}