export class Input {
    constructor(name, value, type, options) {
        this.type = type;
        this.name = name;
        this.options = options;
        this.value = value;
    }

    getType = () => {
        return typeof this.type === "undefined" ? "" : this.type;
    }

    getName = () => {
        return typeof this.name === "undefined" ? "" : this.name;
    }

    getOptions = () => {
        return typeof this.options === "undefined" ? [] : this.options;
    }

    getValue = () => {
        return typeof this.value === "undefined" ? "" : this.value;
    };
}