import React, { Component } from "react";

import {Helmet} from "react-helmet";

export default class PrivacyPolicyPage extends Component {
    render() {
        return (
            <div className="LandingPage content">

                <Helmet>
                    <title>Privacy Policy</title>
                    <meta
                        name="description"
                        content="Pickup Hungary privacy policy"
                    />
                </Helmet>

                <div className="container mt-4 mb-5">

                    <h1>Privacy Policy</h1>

                    <Helmet>
                        <title>Privacy Policy - Pickup Hungary</title>
                    </Helmet>

                    <h2 className="h3">What personal information we collect and why we collect</h2>

                    <p>
                        The personal information we request the customer to make a reservation is only required for this activity is successfully performed, such as name, address or place of collection, phone and email.
                        We never share personal information about our users with anyone except to comply with the law.
                        We never use your personal data with a different expressed in this privacy policy purpose.
                    </p>

                    <h2 className="h3">Contact forms</h2>
                    
                    <p>
                        In the contact forms only the necessary information will be collected for this activity is successfully performed, such as name, address or place of collection, phone, email, Information relating to your booking
                    </p>

                    <h2 className="h3">Comments</h2>
                    
                    <p>
                        When visitors leave comments on the website, we collect the data displayed in the feedback form, as well as the visitor's IP address and user agent string browser to help detect spam.
                    </p> 

                    <h2 className="h3">Analytics</h2>

                    <p>
                        Pickup Hungary service uses Google Analytics.<br />
                        HOW TO USE GOOGLE ANALYTICS PickUp Hungary?<br /><br />
                        This website uses Google Analytics, a web analytics service provided by Google, Inc., a Delaware company whose main office is at 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, USA ("Google").
                        Google Analytics uses "cookies", which are text files placed on your computer, to help the website analyze how users use the website. The information generated by the "cookie" on your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. 
                        Google will use this information on their own in order to keep track of your use of the website, compiling reports on website activity and providing other services relating to website activity and internet usage. 
                        Google may also transfer this information to third parties when so required by law or where such third parties process the information on behalf of Google. Google will not associate your IP address with any other data held by Google. 
                        You can refuse treatment of the data or information by rejecting the use of cookies by selecting the appropriate settings on your browser, however, you should know that doing so may not be able to use the full functionality of this website. By using this website you. Consent to the processing of information about your browsing the website by Google in the manner and for the purposes described above. 
                        This website has been implemented and can be used to display advertising. This site uses the function remarketing with Google Analytics to post online ads, so that other vendors, including Google, will show PickUp Hungary advertising on Internet sites. To this end PickUp Hungary and other vendors, including Google, use cookies source (such as Google Analytics cookie) and third-party cookies (DoubleClick) combined for reporting, and optimize ads based on previous visits to this website.
                    </p>

                    <h2 className="h3">With whom we share your data</h2>

                    <p>
                        PickUp Hungary does not share your data with any company.
                    </p>

                    <h2 className="h3">How long do we keep your data</h2>

                    <p>
                        If you leave a comment, the comment and metadata are preserved indefinitely. This is so that we can recognize and automatically approve comments successive rather than keeping them in a moderation queue.
                    </p>

                    <h2 className="h3">What rights do you have on your data</h2>

                    <p>
                        If you have left comments on this website, you can request to receive an export file of personal information we hold about you, including any data you have provided us. You can also request that we delete any personal information we have about you. This does not include any data that we are obliged to keep administrative, legal or security purposes.
                    </p>

                    <h2 className="h3">Your contact information</h2>

                    <p>
                        PickUp Hungary only stores your personal data when a booking is requested.<br /><br />
                        Responsible for processing personal data:<br />
                        Trade name: Pickup Hungary<br />
                        Company: Spa Thermal Travel Ltd.<br />
                        Company VAT Number: 24958552-2-14<br />
                        Company Reg. number: 14-09-314248<br />
                        Address: Hungary 8600 Siofok, Vak Bottyan u. 32<br />
                        Email: info@pickuphungary.com<br /><br />

                        PickUp Hungary takes no automated decision and / or profiling with user data.
                    </p>

                    <h2 className="h3">Children's Information</h2>
                    <p>
                        Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                    </p>

                    <p>
                        Pickup Hungary does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                    </p>

                </div>

            </div>
        )
    }
}