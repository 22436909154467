import React from 'react';
import {request} from '../../services/BackendService';
import {Overlay, Tooltip} from 'react-bootstrap';

const MostPopularRoutesComponent = () => {

    const [routes, setRoutes] = React.useState([]);
    const [tooltipShow, setTooltipShow] = React.useState(false);
    const target = React.useRef(null);

    React.useEffect(() => {
        request('/getMostPopularRoutes').then(data => setRoutes(data.data));
      }, []);

    return(
        <div className="MostPopularRoutes">
            <h2>The most popular routes right now</h2>

            <div className="row mt-5">
                {
                    routes.map((element) => 
                        <div className="col-12 col-md-6 mt-2">
                            <div className="row">
                                <div className="col-6">
                                    <b>{element.name} </b>
                                    {element.environmentFriendly ? 
                                        <React.Fragment>
                                            <i 
                                                ref={target} 
                                                className="fa fa-regular fa-leaf text-success" 
                                                onMouseEnter={() => setTooltipShow(true)}
                                                onMouseLeave={() => setTooltipShow(false)}
                                                onClick={() => setTooltipShow(!tooltipShow)}
                                                aria-hidden="true"></i>
                                            <Overlay target={target.current} show={tooltipShow} placement="right">
                                                {(props) => (
                                                <Tooltip id="overlay-example" {...props}>
                                                    Environment Friendly option available
                                                </Tooltip>
                                                )}
                                            </Overlay>
                                            <span className="sr-only">Environment Friendly option available</span>
                                        </React.Fragment>
                                        : null}
                                </div>
                                
                                <div className="col-6">
                                    From <span className="text-primary"><i class="fa-light fa-euro-sign"></i> {element.mainRoute.fields.priceEur}</span>
                                </div>
                            </div>
                            <hr />
                        </div>
                    )
                }
            </div>
        </div>
    );

}

export default MostPopularRoutesComponent;