import React from "react";
import HelpSectionComponent from "../../components/HelpSectionComponent";

import {Helmet} from 'react-helmet';
import Carousel from "react-multi-carousel";
import CustomDot from "./CustomDot";
import PickupServiceSteps from "../../components/PickupServiceSteps";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

export default function AboutPage(props) {

    return(
        <div className="AboutPage content">

            <Helmet>
                <title>About Pickup Hungary</title>
                <meta
                    name="description"
                    content="We offer reliable private, tour and airport transfer services in Budapest and surrounding areas. Transport to and from Budapest Airport, as well as transport within Hungary. Best price guarantee, always on time."
                />

                <meta
                    name="keywords"
                    content="About pickup hungary, about our services"
                />
            </Helmet>

            <div className="bg-primary py-4 mb-4 position-relative">
                <div className="about__intro"></div>
                <div className="about__intro-text py-4 position-relative">
                    <div className="ms-4 align-self-end">
                        <h1 className="text-uppercase">About Pickup Hungary</h1>
                        <p className="fs-5">Pickup Hungary is Hungary's #1 pickup service provider, serving thousands of customers since 2014.</p>
                        <p>
                            Part of Spa Thermal Travel Kft., Pickup Hungary is a full-service transportation company specializing in pickup services across Hungary and its neighboring countries.
                        </p>

                        <p>With a strong tecnology background, we guarantee a smooth and reliable service personalized for your needs.</p>

                        <NavLink to="/booking" className="btn btn-outline-light">
                            BOOK NOW
                            <span class="fa fa-chevron-right ms-3 fa-light"></span>
                        </NavLink>
                    </div>
                </div>
            </div>

            <PickupServiceSteps />

            <div className="container-fluid">
                <div className="bg-body-secondary text-center p-3">
                    <Carousel 
                        autoPlay={true}
                        className="py-5"
                        autoPlaySpeed={3000}
                        shouldResetAutoplay
                        slidesToSlide={1}
                        swipeable
                        draggable
                        customDot={<CustomDot />}
                        arrows={false}
                        showDots={true}
                        responsive={responsive}
                    >
                        <div className="about__services__item col-12 p-3">
                            <p className="h1 text-primary">
                            <i class="fa fa-duotone fa-star"></i>
                            </p>
                            <h2>
                                High Quality, Comfortable Vehicles
                            </h2>
                            <p>
                                Our fleet comprises of well maintained and comfortable 
                                vehicles to ensure your journey is as pleasant as possible. 
                                We prioritize safety, cleanliness, and comfort, regularly 
                                updating our vehicles to meet the highest standards.
                            </p>
                        </div>

                        <div className="about__services__item col-12 p-3">
                            <p className="h1">
                                <i class="fa-duotone fa-car"></i> 
                            </p>
                            <h2>
                                
                                Polite, Friendly Drivers and Staff
                            </h2>
                            <p>
                                Our team of courteous and professional drivers and staff are 
                                dedicated to delivering exceptional customer service. 
                                We believe that a friendly and helpful attitude can make a 
                                significant difference in your travel experience.
                            </p>
                        </div>

                        <div className="about__services__item col-12 p-3">
                            <p className="h1 text-primary">
                                <i class="fa-duotone fa-hand-wave"></i>
                            </p>
                            <h2>
                                Meet & Greet
                            </h2>
                            <p>
                                We offer comprehensive meet and greet services at airports,
                                railway stations, and hotels to facilitate a smooth and 
                                hassle-free start to your journey. Our representatives 
                                are always ready to assist you with your luggage and 
                                provide you with any necessary information.
                            </p>
                        </div>

                        <div className="about__services__item col-12 p-3">
                            <p className="h1">
                                <i class="fa-duotone fa-phone"></i>
                            </p>
                            <h2>
                                Customers First
                            </h2>
                            <p>
                                We pride ourselves on our reliability, offering consistent 
                                and dependable services 24/7, every day of the year. 
                                Our commitment to punctuality means you can always count on us,
                                regardless of the time or day.
                            </p>
                        </div>

                        <div className="about__services__item col-12 p-3">
                            <p className="h1 text-primary">
                                <i class="fa-duotone fa-briefcase"></i>
                            </p>
                            <h2>
                                B2B Service
                            </h2>
                            <p>
                                Partner with us for professional and reliable 
                                business-to-business transfer solutions tailored to 
                                meet the unique needs of your organization. 
                                Our B2B services are designed to support corporate clients
                                with efficient and customized transportation solutions, 
                                enhancing their business operations.
                            </p>
                        </div>
                    </Carousel>
                </div>
            </div>

            <HelpSectionComponent />
        </div>
    );
}