import React from "react"
import { NavLink } from "react-router-dom/cjs/react-router-dom"

export default function PickupServiceSteps() {
    return (
        <div className="bg-light p-5">
        <h3 className="text-uppercase">Pickup service, 3 easy steps</h3>

        <div className="row">
            <div className="col-lg-4 col-12">
                <p className="fs-4">
                    <span className="fs-2 me-2"><i class="fa-duotone fa-1"></i></span>
                    BEFORE YOUR TRIP
                </p>
                <p>
                    You are welcome to make any changes to your trip, at least 24 hours before your trip - free of charge.
                </p>

                <p>
                    24 hours before your trip, we will send an automated e-mail with all the important information. Make sure to check your junk folder 
                    (but we promise, it won't be junk).
                </p>
                
            </div>

            <div className="col-lg-4 col-12">
                <p className="fs-4">
                    <span className="fs-2 me-2 text-primary"><i class="fa-duotone fa-2"></i></span>
                    AT PICK UP
                </p>
                <p>
                    Welcome to Hungary! Your driver will send you a message to your phone half an hour before your trip begins. For airport pickups with business & premium classes, the driver will wait at your gate with your nameplate.
                </p>
                <p>
                    In case of delays, 90 minutes of free waiting time at the airport is also included.
                </p>
            </div>

            <div className="col-lg-4 col-12">
                <p className="fs-4">
                    <span className="fs-2 me-2"><i class="fa-duotone fa-3"></i></span>
                    DURING
                </p>
                <p>
                    Sit back, relax and enjoy your trip. 
                    Ask your driver to turn on the radio, silence it, or stop for a quick stop.
                    Rest assured, your car will be clean, well kept with a safe, professional driver, 
                    taking care of you.
                </p>
            </div>
        </div>
    </div>
    )
}