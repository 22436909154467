import React from 'react';
export default function BookingPageSummaryComponen({common}) {
    return (
        <div className="mb-5">
            <div className="BookingPage__summary row mb-4">
                <div className="BookingPage__summary__category col-md-6 col-12">
                    <img src={common.image} alt={"Picture of" + common.category} />
                    <p class="h4 font-weight-bold">{common.category}</p>
                </div>

                <div className="BookingPage__summary__details offset-md-2 col-md-4 col-12">
                    <p>
                        <i class="fas fa-users"></i> {common.capacity}
                        <i class="fas fa-suitcase-rolling ms-3"></i> {common.max_luggage}
                    </p>

                    <p>
                        <i class="fas fa-check-circle text-primary"></i> Free Waiting Time: 60 minutes at airports, 15 minutes everywhere else
                    </p>

                    <p className="h5">
                        Price: {
                            !common.discount_code ? 
                                common.price :
                                <span>
                                    <strike class="text-danger">{common.original_price}</strike>
                                    &nbsp;{common.price}
                                </span>
                        } EUR
                    </p>
                </div>
            </div>

            <hr />
        </div>
        
    );
}