import React from "react";

export default class ScrollToTopComponent extends React.Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	render() {
        return (
            <React.Fragment></React.Fragment>
        );
    };
}