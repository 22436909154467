import React from "react";

import HelpSectionComponent from "../../components/HelpSectionComponent";
import BookingPageSearchSectionComponent from "./components/BookingPageSearchSectionComponent";
import BookingPageStepsComponent from "./components/BookingPageStepsComponent";
import LoaderComponent from "../../components/LoaderComponent";
import MostPopularRoutesComponent from "../../components/MostPopularRoutesComponent";

import {request, post} from "../../services/BackendService";

import FormValidationService from "../../services/FormValidationService";
import { Input } from "../../services/FormValidationService/Input";

import * as yup from 'yup';

import {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import {Helmet} from 'react-helmet';
import mixpanel from "mixpanel-browser";

export default class BookingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: typeof props.match.params.step !== "undefined" ? Number(props.match.params.step) : 1,
            loader: false,
            step2: {
                full_name: '',
                email_address: '',
                phone_number: '',
                phone_extension: '',
                date: '',
                date_picker: '',
                time: '',
                return_date: '',
                return_date_picker: '',
                return_time: '',
                type_of_child_seat: '',
                flight_number_checkbox: 0,
                flight_number: '',
                comments: '',
                adults: 0,
                billing_address: '',
                billing_name: '',
                billing_postcode: '',
                billing_city: '',
                billing_country: '',
                infant_carrier: 0,
                convertible_seat: 0,
                booster_seat: 0,
                child_seat: '',
                child_seat_open: false,
                terms_accepted: false,
                have_discount_code: false,
                sent_discount_code: false,
                discount_code_response: false,
                discount_code: ''
            },
            common: {
                destination_from: '',
                destination_to: '',
                from_lat: '',
                from_lng: '',
                to_lat: '',
                to_lng: '',
                passengers: 0,
                return_journey_checkbox: 1,
                original_price: 0,
                discount_percentage: 0,
                discount_code: '',
                price: 0,
                category: '',
                capacity: 4,
                image: '',
                max_luggage: 0,
                booking_reference: ''
            },
            step1: {
                validated: false,
                routes: []
            },
            draft: {
                destination_from: '',
                destination_to: '',
                from_lat: '',
                from_lng: '',
                to_lat: '',
                to_lng: '',
                passengers: 0,
                return_journey_checkbox: 0
            },
            step4: {
                paymentLink: ''
            },
            noResults: {
                full_name: '',
                email_address: '',
                sent: false
            },
            errors: {}
        };

        if (props.location.state) {
            this.state = {
                ...this.state,
                ...props.location.state
            }
        }
    }

    goToStep = (step, loader = false) => {

        if (loader === true) {
            this.setState({
                ...this.state,
                loader
            }, () => {
                document.getElementById('BookingPage__content').scrollIntoView()
            });
            return;
        }

        let path = '/booking/' + step; 
        this.props.history.push(path);

        this.setState({
            ...this.state,
            step,
            loader,
        }, () => {
            document.getElementById('BookingPage__content').scrollIntoView()
        });
    }

    handleChange = ({target, callAsync}) => {
        let splitter = target.id;

        if (!target.id)
            splitter = target.name;

        let split = splitter.split(".");

        this.setState((prev) => ({
           ...prev,
            [split[0]]: {
                ...prev[split[0]],
                [split[1]]: split[1].includes("checkbox") ? target.checked : target.value
            }
        }), callAsync ? callAsync : null);
    }

    acceptTerms = (event) => {
        this.setState((prev) => ({
            ...prev,
            step2: {
                ...prev.step2,
                terms_accepted: event.target.checked
            }
        }))
    }

    validateDiscountCode = async () => {
        let state;

        if (!this.state.step2.discount_code) {
            state = {
                ...this.state,
                step2: {
                    ...this.state.step2,
                    sent_discount_code: true,
                    discount_code_response: false
                },
                common: {
                    ...this.state.common,
                    price: this.state.common.original_price,
                    discount_code: '',
                    discount_percentage: 0
                }
            }

            this.setState(state);
            return;
        }

        request(
            '/validateDiscountCode', 
            {
                code: this.state.step2.discount_code
            }
        ).then(response => {
            // discount code was valid
            state = {
                ...this.state,
                step2: {
                    ...this.state.step2,
                    discount_code: response.data.code,
                    discount_percentage: response.data.percentage,
                    sent_discount_code: true,
                    discount_code_response: response.data.code ? true : false
                },
                common: {
                    ...this.state.common,
                    discount_code: response.data.code,
                    discount_percentage: response.data.percentage,
                    price: this.state.common.original_price - this.state.common.original_price / 100 * response.data.percentage
                }
            };

            this.setState(state);
        });
    }

    validateSearch = async (event) => {
        event.preventDefault();

        const schema = yup.object({
            destination_from: yup.string().required('Destination From is required'),
            destination_to: yup.string().required('Destination To is required'),
            passengers: yup.number().min(1, 'Minimum 1 passenger'),
            from_lat: yup.number().required(),
            from_lng: yup.number().required(),
            to_lat: yup.number().required(),
            to_lng: yup.number().required()
        });

        let validated = await this.validateSchema(schema, this.state.draft);

        let newState = {
            ...this.state,
            step1: {
                ...this.state.step1,
                validated
            }
        };

        mixpanel.track('Search', {
            type: 'Booking Page', 
            destination_from: {
                lat: this.state.draft.from_lat,
                lng:  this.state.draft.from_lng,
                value: this.state.draft.destination_from
            },
            destination_to: {
                lat: this.state.draft.to_lat,
                lng:  this.state.draft.to_lng,
                value: this.state.draft.destination_to
            },
        });

        if (validated) {
            newState.common = {
                ...newState.common,
                ...newState.draft
            };

            this.setState(newState, () => {
                this.findRoutes(); 
                this.goToStep(0, true)
            });
        }
    }

    findRoutes = async () => {
        request(
            '/findRoute', 
            {
                fromLat: this.state.common.from_lat,
                fromLng: this.state.common.from_lng,
                toLat: this.state.common.to_lat,
                toLng: this.state.common.to_lng,
                passengers: this.state.common.passengers,
            }
        ).then(response => {
            this.setState({
                ...this.state,
                step1: {
                    ...this.state.step1,
                    routes: response.data
                }
            }, () => this.goToStep(1));
        });
        
    }

    preview = async (event) => {
        event.preventDefault();

        const schema = yup.object({
            full_name: yup.string().required('Full Name is required'),
            email_address: yup.string().required('E-mail Address is required').email('Invalid e-mail'),
            phone_number: yup.string().required('Phone Number is required'),
            phone_extension: yup.string().required('Phone Extension is required'),
            date: yup.string().required('Date is required'),
            time: yup.string().required('Time is required').test(
                'timeIsNotLessThan24Hours',
                'Your booking is in less than 24 hours. Try contacting us on info@pickuphungary.com for quote.',
                (value) => {
                    if (
                        typeof this.state.step2.date_picker !== "object"
                    )
                        return true;

                    if (
                        !this.state.step2.time
                    )
                        return true;

                    let beforeDate = new Date();
                    beforeDate.setDate(beforeDate.getDate() + 1);

                    let date = new Date(this.state.step2.date_picker.toString());
                    let splitDate = this.state.step2.time.split(':');

                    date.setHours(splitDate[0]);
                    date.setMinutes(splitDate[1]);

                    return date > beforeDate;
                } 
            ),
            return_date: yup.string().when('return_journey_checkbox', {
                is: 1,
                then: yup.string().required('Return Date is required').test(
                    'returnDateHigherThanDate',
                    'Return date is before departure date.',
                    (value) => {
                        if (
                            typeof this.state.step2.return_date_picker !== "object" ||
                            typeof this.state.step2.date_picker !== "object"
                        )
                            return true;

                        if (
                            !this.state.step2.return_time ||
                            !this.state.step2.time
                        )
                            return true;

                        let returnDate = new Date(this.state.step2.return_date_picker.toString());
                        let splitReturn = this.state.step2.return_time.split(':');

                        returnDate.setHours(splitReturn[0]);
                        returnDate.setMinutes(splitReturn[1]);

                        let date = new Date(this.state.step2.date_picker.toString());
                        let splitDate = this.state.step2.time.split(':');

                        date.setHours(splitDate[0]);
                        date.setMinutes(splitDate[1]);

                        return date < returnDate;
                    } 
                )
            }),
            return_time: yup.string().when('return_journey_checkbox', {
                is: 1,
                then: yup.string().required('Return Time is required')
            }),
            adults: yup.number().required('Passengers field is required').min(1, 'Invalid field'),
            child_seat: yup.string().test(
                'maximumCapacity', 
                'There isn\'t enough capacity. You need a higher category car.', 
                (value) => {
                    if (
                        parseInt(this.state.step2.booster_seat) + 
                        parseInt(this.state.step2.infant_carrier) + 
                        parseInt(this.state.step2.convertible_seat) +
                        parseInt(this.state.step2.adults)
                        > this.state.common.capacity
                    ) {
                        return false;
                    }

                    return true;
                }
            ),
            billing_name: yup.string().required('Billing Name is required'),
            billing_address: yup.string().required('Billing Address is required'),
            billing_postcode: yup.string().required('Billing Postcode is required'),
            billing_city: yup.string().required('Billing City is required'),
            billing_country: yup.string().required('Billing Country is required'),
            comments: yup.string()
        }); 

        const isValid = await this.validateSchema(schema, {...this.state.step2, return_journey_checkbox: this.state.common.return_journey_checkbox});

         if (isValid) { 
            this.goToStep(2, true);
            this.createNewBooking();
         }
    }

    getBookingObject = () => {
        return {
            "email_address": this.state.step2.email_address,
            "phone_number": this.state.step2.phone_extension + this.state.step2.phone_number,
            "full_name": this.state.step2.full_name,
            "billing_address": this.state.step2.billing_address,
            "billing_name": this.state.step2.billing_name,
            "billing_postcode": this.state.step2.billing_postcode,
            "billing_city": this.state.step2.billing_city,
            "billing_country": this.state.step2.billing_country,
            "date": this.state.step2.date + " " + this.state.step2.time,
            "return_date": this.state.common.return_journey_checkbox ? this.state.step2.return_date + " " + this.state.step2.return_time : null,
            "flight_number": this.state.step2.flight_number,
            "comments": this.state.step2.comments,
            "adults": this.state.step2.adults,
            "passengers": this.state.common.passengers,
            "convertible_seat": this.state.step2.convertible_seat,
            "booster_seat": this.state.step2.booster_seat,
            "infant_carrier": this.state.step2.infant_carrier,
            "destination_from": this.state.common.destination_from,
            "destination_to": this.state.common.destination_to,
            "from_lat": this.state.common.from_lat,
            "from_lng": this.state.common.from_lng,
            "to_lat": this.state.common.to_lat,
            "to_lng": this.state.common.to_lng,
            "price": this.state.common.price,
            "category": this.state.common.category,
            "capacity": this.state.common.capacity,
            "max_luggage": this.state.common.max_luggage,
            "original_price": this.state.common.original_price,
            "discount_code": this.state.common.discount_code,
            "discount_percentage": this.state.common.discount_percentage
        };
    }

    createNewBooking = () => {

        let object = this.getBookingObject();

        mixpanel.track('New Booking', object);

        post('/createBooking', object).then((response) => {
            this.setState({
                ...this.state,
                common: {
                    ...this.state.common,
                    booking_reference: response.data.booking_reference
                }
            })
            this.goToStep(3);
        });

        
    }

    validateSchema = async (schema, context) => {
        let errors = {};

        return await schema.validate(context, {abortEarly: false})
        .then()
        .catch(err => 
            err.inner.forEach(element => {
                element.path = element.path.replace(/\"|\[|\]/g, '');
                errors[element.path] = element.message;
           })
        ).finally(() => {
            this.setState({
                ...this.state,
                errors
            });
         });
    }

    sameAsAboveBillingName = ({target}) => {
        if (target.checked) {
            this.setState({
                ...this.state,
                step2: {
                    ...this.state.step2,
                    billing_name: this.state.step2.full_name
                }
            });
        } else {
            this.setState({
                ...this.state,
                step2: {
                    ...this.state.step2,
                    billing_name: ''
                }
            });
        }
    }
    
    chooseCategory = (category, image, price, capacity, max_luggage) => {

        // Double the price if return journey
        if (this.state.common.return_journey_checkbox) {
            price = price*2;
        }

        mixpanel.track('Booking category chosen', this.getBookingObject());

        this.setState({
            ...this.state,
            common: {
                ...this.state.common,
                price,
                original_price: price,
                category,
                capacity,
                image,
                max_luggage,
                discount_code: '',
                discount_percentage: 0
            }
        }, () => this.goToStep(2));
    }

    pay = (event) => {
        event.preventDefault();

        let validationService = new FormValidationService(this.state.step4.rules);

        // Cloning object
        let Inputs = {...this.state.step4};
        delete Inputs.rules;
        delete Inputs.validated;

        Object.keys(Inputs).forEach((input) => {
            validationService.addInput(new Input(input, Inputs[input]));
        });

        let validated = validationService.validate();

        let newState = {
            ...this.state,
            step4: {
                ...this.state.step4,
                validated,
                sent: true
            }
        };

        this.setState(newState, () => validated ? this.goToStep(5) : null);
    }

    childSeat = () => {
        document.addEventListener('mousedown', this.childSeatClose);
        document.addEventListener('keydown', this.childSeatClose);
        
        this.setState({
            ...this.state,
            step2: {
                ...this.state.step2,
                child_seat_open: true
            }
        }, () => {
            var doc = document.getElementById('child_seat');
            doc.addEventListener('blur', this.childSeaClose);
        });
    }

    childSeatClose = (event) => {
        const close = () => this.setState({
            ...this.state,
            step2: {
                ...this.state.step2,
                child_seat_open: false
            }
        }, () => {
            document.removeEventListener('mousedown', this.childSeatClose);
            document.removeEventListener('blur', this.childSeatClose);
            document.removeEventListener('keydown', this.childSeatClose);
        });

        if (event.type === 'keydown' && event.keyCode === 27) {
            close();
            return;
        } else if(event.type === 'blur' || event.type === 'mousedown') {
            var doc = document.getElementById('child_seat');

            if (!doc.contains(event.target)) {
                close();
                return;
            }
        }
          
    }

    childSeatIncrease = (type) => {
        if (this.state.step2[type] !== 'undefined') {
            this.setState({
                ...this.state,
                step2: {
                    ...this.state.step2,
                    [type]: this.state.step2[type] + 1
                }
            }, () => this.buildChildSeatText());
        }
    }

    childSeatDecrease = (type) => {
        if (this.state.step2[type] !== 'undefined') {

            if (this.state.step2[type] === 0)
                return;

            this.setState({
                ...this.state,
                step2: {
                    ...this.state.step2,
                    [type]: this.state.step2[type] - 1
                }
            }, () => this.buildChildSeatText());
        }
    }

    buildChildSeatText = () => {
        let text = '';

        if (this.state.step2.infant_carrier !== 0) {
            text = this.state.step2.infant_carrier.toString() + ' Infant Carrier, ';
        }

        if (this.state.step2.convertible_seat !== 0) {
            text += this.state.step2.convertible_seat.toString() + ' Convertible Seat, ';
        }

        if (this.state.step2.booster_seat !== 0) {
            text += this.state.step2.booster_seat.toString() + ' Booster Seat';
        }

        this.setState({
            ...this.state,
            step2: {
                ...this.state.step2,
                child_seat: text
            }
        });
        
    }

    adults = () => {
        let elements = [];
    
        for (var i = 1; i <= this.state.common.capacity; i++) {
            elements.push(i);
        }
    
        return elements;
    }

    handleAutocompleteSelect = (address, fromOrTo) => {
        let keyLat = fromOrTo + '_lat';
        let keyLng = fromOrTo + '_lng';

        let keyDestination = 'destination_' + fromOrTo;

        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) => {
                this.setState({
                ...this.state,
                draft: {
                    ...this.state.draft,
                    [keyLat]: lat,
                    [keyLng]: lng,
                    [keyDestination]: address
                }
            })});
    }

    requestRoute = (event) => {
        event.preventDefault();
        
        if (event.target.checkValidity()) {
            event.target.classList.remove('was-validated');

            post('/requestRoute', {
                passengers: this.state.common.passengers,
                address_from: this.state.common.destination_from,
                address_to: this.state.common.destination_to,
                full_name: this.state.noResults.full_name,
                email_address: this.state.noResults.email_address
            }).then(response => {
                this.setState({
                    ...this.state,
                    noResults: {
                        sent: true
                    }
                });
            }).catch((error) => {
                
            });
            
        } else {
            event.target.classList.add('was-validated');
        }
    }

    goToPayment = async () => {
        this.goToStep(3, true);
        mixpanel.track('Payment initiated', this.getBookingObject());
        post('/startPayment', {booking_reference: this.state.common.booking_reference}).then((response) => {
            const paymentLink = response.data.payment_link;

            this.setState({
                ...this.state,
                step4: {
                    ...this.state.step4,
                    paymentLink
                }
            }, () => {
                this.goToStep(4);
                setTimeout(() => window.location.href = paymentLink, 3000);
            })
            
        });
        
    }

    render() {
        return(
            <div className="BookingPage content">
                <Helmet>
                    <title>Book Private Transfer in Hungary and Budapest</title>
                    <meta
                        name="description"
                        content="Book your safe and reliable low cost, airport and intercity transfers for individuals and groups in/from Hungary and Budapest. Book your transfer in  advance, online. Best price guarantee, always on time."
                    />

                    <meta
                        name="keywords"
                        content="book airport transfer hungary, book airport transfer budapest, book private transfer budapest, book private transfer hungary"
                    />
                </Helmet>
                
                <BookingPageSearchSectionComponent 
                    validateSearch={this.validateSearch}
                    handleChange={this.handleChange}
                    handleAutocompleteSelect={this.handleAutocompleteSelect}
                    {...this.state}
                />

                {!this.state.step1.validated && !this.state.loader ?
                    <div id="BookingPage__content">
                        <div className="container mb-5 mt-5" >
                            <MostPopularRoutesComponent /> 
                        </div>
                    </div>
                    : null
                }

                {this.state.loader && <div id="BookingPage__content"><LoaderComponent /></div>}

                {this.state.step1.validated && !this.state.loader &&
                <div id="BookingPage__content">
                    <div className="container mb-5" >
                        <div class="mt-5"></div>
        
                        <div className="BookingPage__info">                            
                            <p className="small">
                                <button 
                                    className={"btn btn-sm btn-link " + (this.state.step === 1 ? "btn-active" : "") }
                                    onClick={() => this.goToStep(1)}
                                    disabled={(this.state.step === 5 ? true : false)}
                                >
                                    Choose Category
                                </button>
                                <i className="fas fa-chevron-right"></i>
                                <button 
                                    className={"btn btn-sm btn-link " + (this.state.step === 2 ? "btn-active" : "")}
                                    onClick={() => this.goToStep(2)}  
                                    disabled={(this.state.step < 2 || this.state.step === 5 ? true : false)}
                                >
                                    Give us your details
                                </button>
                                <i className="fas fa-chevron-right"></i>
                                <button 
                                    className={"btn btn-sm btn-link " + (this.state.step === 3 ? "btn-active" : "")}
                                    onClick={() => this.goToStep(3)}
                                    disabled={(this.state.step < 3 || this.state.step === 5 ? true : false)}
                                >
                                    Preview
                                </button>
                                <i className="fas fa-chevron-right"></i>
                                <button 
                                    className={"btn btn-sm btn-link " + (this.state.step === 4 ? "btn-active" : "")}
                                    onClick={() => this.goToStep(4)}
                                    disabled={(this.state.step < 4 || this.state.step === 5 ? true : false)}
                                >
                                    Payment
                                </button>
                                <i className="fas fa-chevron-right"></i>
                                <button 
                                    className={"btn btn-sm btn-link " + (this.state.step === 5 ? "btn-active" : "")}
                                    onClick={() => this.goToStep(5)}  
                                    disabled={(this.state.step < 5 ? true : false)}
                                    >
                                    All done
                                </button>
                            </p>
                            
                        </div>
        
                        <div className="BookingPage__content mt-5">
                            <BookingPageStepsComponent 
                                goToStep={this.goToStep} 
                                handleChange={this.handleChange}
                                preview={this.preview}
                                sameAsAboveBillingName={this.sameAsAboveBillingName}
                                chooseCategory={this.chooseCategory}
                                pay={this.pay}
                                childSeat={this.childSeat}
                                childSeatIncrease={this.childSeatIncrease}
                                childSeatDecrease={this.childSeatDecrease}
                                adults={this.adults}
                                requestRoute={this.requestRoute}
                                goToPayment={this.goToPayment}
                                acceptTerms={this.acceptTerms}
                                validateDiscountCode={this.validateDiscountCode}
                                {...this.state}
                            />
                        </div>
                    
                    </div>
                    <HelpSectionComponent/>
                </div>
                }

            </div>
        );
    }
    
}