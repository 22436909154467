import axios from 'axios';

export function request(url, data) {
    const params = {
        ...data
    };

    return axios.get(process.env.REACT_APP_API_URL + url, {params});
}

export function post(url, data) {
    return axios.post(process.env.REACT_APP_API_URL + url, data);
}