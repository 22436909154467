import React from "react";

export default function CustomDot({ onClick, ...rest }) {
    const { active } = rest;
    return (
        <button
            className="btn btn-link"
            onClick={() => onClick()}
        >
            <i className={`fa-circle ${active ? "fa-solid" : "fa-regular"}`} />
        </button>
    );
  };