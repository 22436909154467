import React from "react";

import LoaderComponent from "../../components/LoaderComponent";
import {request} from "../../services/BackendService";

import mixpanel from "mixpanel-browser";

export default class BookingSummaryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            booking: false
        };
    }

    componentDidMount() {
        request('/getBookingByReference', {
            booking_reference: this.props.match.params.bookingReference
        }).then(response => {

            this.setState({
                booking: {
                    ...response.data
                },
            });

            mixpanel.track('Booking page summary loaded', response.data);
        });
    }

    render() {
        return (
            <div className="BookingPage content">

                <div id="BookingPage__content">
                    <div className="container mb-5" >
                        <div class="mt-5"></div>
                        {!this.state.booking && <LoaderComponent />}

                        {this.state.booking &&

                            <div className="BookingPage__box_small BookingPage__step5">
                                <div className="BookingPage__box__content">

                                    <h2 className="display-5 mb-4">
                                        <i className="fas fa-check pr-4 text-primary"></i>
                                        Your booking is now {this.state.booking.status || 'confirmed'}
                                    </h2>
                                    <p class="leadName">
                                        Your booking is now {this.state.booking.status || 'confirmed'}. Please find below the summary. The confirmation has also been sent to you
                                        via e-mail to {this.state.booking.email_address} ! Thank you for choosing <b>Pickup Hungary</b>.
                                    </p>

                                    <p>
                                        Booking reference: {this.state.booking.booking_reference}
                                    </p>

                                    <p>
                                        Status: <b>{this.state.booking.status || 'confirmed'}</b>
                                    </p>

                                    <hr className="mt-4 mb-4" />

                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <b>Trip Summary</b>

                                            <div className="mt-2"></div>
                                            <p>
                                                Category: {this.state.booking.category.name}<br />
                                                Max. Luggages: {this.state.booking.category.max_luggage}<br />
                                                Pick-up Location: {this.state.booking.destination_from.address}<br />
                                                Drop Off Location: {this.state.booking.destination_to.address}<br />
                                                Type: {this.state.booking.return_date ? "Return" : "One-Way"}<br />
                                                Date: {this.state.booking.date}<br />
                                                {this.state.booking.return_date && "Date Back: " + this.state.booking.return_date}
                                            </p>
                                        </div>

                                        <div className="col-md-6 col-12">
                                            <b>Billing Details</b>
                                            <address>
                                                {this.state.booking.billing?.name} <br />
                                                {this.state.booking.billing?.address} <br />
                                                {this.state.booking.billing?.city} <br />
                                                {this.state.booking.billing?.postcode} <br />
                                                {this.state.booking.billing?.country}
                                            </address>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6 col-12">
                                            <b>Passenger Details</b>

                                            <div className="mt-2"></div>
                                            <p>
                                                {this.state.booking.full_name}<br />
                                                {this.state.booking.email_address}<br />
                                                {this.state.booking.phone_number}<br /><br />
                                                {this.state.booking.passengers.adults} adults<br />
                                                {this.state.booking.passengers.infant_carrier} infant carrier<br />
                                                {this.state.booking.passengers.convertible_seat} convertible seats<br />
                                                {this.state.booking.passengers.booster_seat} booster seats<br />
                                            </p>
                                        </div>

                                        <div className="col-md-6 col-12">
                                            <b>Payment Details</b>

                                            <div className="mt-2"></div>
                                            <p>
                                                <b>Total Price: {this.state.booking.total_price ?? this.state.booking.price} {this.state.booking.currency || 'EUR'}</b><br />
                                                Base: {this.state.booking.price} 
                                                {this.state.booking.discount?.code ? <span> (including {this.state.booking.discount?.percentage}% off)</span> : null} {this.state.booking.currency || 'EUR'}<br />
                                                Extra: {this.state.booking.extra_price ?? 0} {this.state.booking.currency || 'EUR'}
                                            </p>
                                            <p>
                                                Payment Type: {this.state.booking.payment?.type}
                                            </p>
                                        </div>

                                        <div className="col-md-6 col-12">
                                            <b>More</b>

                                            <div className="mt-2"></div>
                                            <p>
                                                Comments: {this.state.booking.comments}<br />
                                                Flight Number: {this.state.booking.flight_number}
                                            </p>
                                        </div>
                                    </div>

                                    <p className="mt-4">
                                        <small>The invoice will be sent to your e-mail address within 3 working days.</small>
                                    </p>

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}