import React from 'react';

import {geocodeByAddress, getLatLng} from 'react-places-autocomplete';

import * as yup from 'yup';

import {request} from "../../services/BackendService";
import { withRouter } from 'react-router-dom';

import Spinner from 'react-bootstrap/Spinner';
import mixpanel from 'mixpanel-browser';
import InputGroup from '../../pages/BookingPage/components/BookingPageSearchSectionComponent/InputGroup';
import { Alert } from 'react-bootstrap';

class SearchSectionComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            draft: {
                destination_from: '',
                destination_to: '',
                from_lat: '',
                from_lng: '',
                to_lat: '',
                to_lng: '',
                return_journey_checkbox: 0,
            },
            'errors': {},
            loading: false,
            showInfo: props.showInfo
        };
    }
    
    handleChange = ({target}) => {

        let splitter = target.id;

        if (!target.id)
            splitter = target.name;

        let split = splitter.split(".");

        this.setState((prev) => ({
           ...prev,
            [split[0]]: {
                ...prev[split[0]],
                [split[1]]: split[1].includes("checkbox") ? target.checked : target.value
            }
        }));
    }

    handleAutocompleteSelect = (address, fromOrTo) => {
        let keyLat = fromOrTo + '_lat';
        let keyLng = fromOrTo + '_lng';

        let keyDestination = 'destination_' + fromOrTo;

        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) => {
                this.setState({
                ...this.state,
                draft: {
                    ...this.state.draft,
                    [keyLat]: lat,
                    [keyLng]: lng,
                    [keyDestination]: address
                }
            })});
    }

    validateSearch = async (event) => {
        event.preventDefault();

        this.setState((prev) => ({
            ...prev,
            loading: true,
            errors: {
                missing: false
            }
        }));

        const schema = yup.object({
            destination_from: yup.string().required('Destination From is required'),
            destination_to: yup.string().required('Destination To is required'),
            from_lat: yup.number().required('Please choose an item from the list'),
            from_lng: yup.number().required('Please choose an item from the list'),
            to_lat: yup.number().required('Please choose an item from the list'),
            to_lng: yup.number().required('Please choose an item from the list')
        });

        try {
            let validated = await this.validateSchema(schema, this.state.draft, { abortEarly: false });

            mixpanel.track('Search', {
                type: 'Search Section', 
                destination_from: {
                    lat: this.state.draft.from_lat,
                    lng:  this.state.draft.from_lng,
                    value: this.state.draft.destination_from
                },
                destination_to: {
                    lat: this.state.draft.to_lat,
                    lng:  this.state.draft.to_lng,
                    value: this.state.draft.destination_to
                },
            });

            if (typeof validated === "undefined" || !validated) {
                console.log(validated);
                this.setState((prev) => ({
                    ...prev,
                    loading: false,
                    errors: {
                        missing: true
                    }
                }));
                return;
            }

            this.findRoutes();
        } catch (err) {
            console.log(err);
            this.setState((prev) => ({
                ...prev,
                loading: false,
                errors: {
                    missing: true
                }
            }));
        }
    }

    validateSchema = async (schema, context) => {
        let errors = {};

        return await schema.validate(context, {abortEarly: false})
        .then()
        .catch(err => 
            err.inner.forEach(element => {
                element.path = element.path.replace(/\"|\[|\]/g, '');
                errors[element.path] = element.message;
           })
        ).finally(() => {
            this.setState({
                ...this.state,
                errors
            });
         });
    }

    findRoutes = async () => {
        request(
            '/findRoute', 
            {
                fromLat: this.state.draft.from_lat,
                fromLng: this.state.draft.from_lng,
                toLat: this.state.draft.to_lat,
                toLng: this.state.draft.to_lng
            }
        ).then(response => {
            this.props.history.push({
                pathname: '/booking/1',
                state: {
                    step: 1,
                    step1: {
                        validated: true,
                        routes: response.data
                    },
                    common: {
                        destination_from: this.state.draft.destination_from,
                        destination_to: this.state.draft.destination_to,
                        from_lat: this.state.draft.from_lat,
                        from_lng: this.state.draft.from_lng,
                        to_lat: this.state.draft.destination_to_lat,
                        to_lng: this.state.draft.destination_to_lng,
                        return_journey_checkbox: this.state.draft.return_journey_checkbox
                    },
                    draft: {
                        destination_from: this.state.draft.destination_from,
                        destination_to: this.state.draft.destination_to,
                        from_lat: this.state.draft.from_lat,
                        from_lng: this.state.draft.from_lng,
                        to_lat: this.state.draft.to_lat,
                        to_lng: this.state.draft.to_lng,
                        return_journey_checkbox: this.state.draft.return_journey_checkbox
                    },
                }  
            })
        });
        
    }
    
    render() {
        return(
            <div className="SearchSection">
                <div className="SearchSection__layer pt-mb-5 pb-md-5 pt-2 pb-4">
                    <div className="container-lg">
                        <div className="row">
                            <div className="col-12 col-md-5 pt-md-4 mt-4 SearchSection__promo">
                                <h1>Budapest and Hungary - airport, city and hotel transfer</h1>
                            </div>
                            <form className="col-12 col-md-5 offset-md-2 SearchSection__form mt-md-4" onSubmit={this.validateSearch}>
                                
                                <div className="d-flex flex-column gap-3">
                                    <InputGroup handleChange={this.handleChange} handleAutocompleteSelect={this.handleAutocompleteSelect} draft={this.state.draft} errors={this.state.errors} />
                                </div>

                                <div className="d-block">
                                    {this.state.errors && this.state.errors.missing && (
                                        <Alert variant='danger' className="mt-2">
                                            Please select location from the list
                                        </Alert>
                                    )}
                                </div>
                                
    
                                <div className="block mt-2 mb-3 text-white form-check">
                                    <input type="checkbox" className="mr-2 form-check-input" onChange={(event) => this.handleChange({target: { id: 'draft.return_journey_checkbox', checked: event.target.checked}})}/> Return Journey
                                </div>
    
                                <button type="submit" className="btn btn-primary btn-lg SearchSection__form__submit" disabled={this.state.loading}>
                                    {!this.state.loading ? "Search" : <Spinner animation='grow' role='status'><span className='sr-only'>Loading...</span></Spinner>}
                                </button>
                            </form>
                        </div>

                        {this.state.showInfo ? <div className="row mt-5 SearchSection__info">
                            <div className="col-12 col-sm-6 col-md-4 mt-0 mt-md-0">
                                <div className="row">
                                    <div className="col-2 col-md-2">
                                        <i class="fa fa-calendar-check SearchSection__info__icon"></i>
                                    </div>
                                    <div className="col-10 col-md-9">
                                        Free 24 hour cancellation or changes
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 mt-sm-0 col-md-4 mt-4">
                                <div className="row">
                                    <div className="col-2 col-md-2">
                                        <i class="fa fa-dollar-sign SearchSection__info__icon"></i>
                                    </div>
                                    <div className="col-10 col-md-9">
                                        Fixed prices, we don't work with third parties
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 col-md-4 mt-4 mt-md-0">
                                <div className="row">
                                    <div className="col-2 col-md-2">
                                        <i class="fa fa-credit-card SearchSection__info__icon"></i>
                                    </div>
                                    <div className="col-10">
                                        Free and safe online payment, with all major credit and debit cards
                                    </div>
                                </div>
                            </div>
                        </div> : null }
                    </div>
                </div>
                
            </div>
        );
    }
}

export default withRouter(SearchSectionComponent);