import React, { Component } from "react";
import { NavLink} from 'react-router-dom';

import SearchSectionComponent from "../../components/SearchSectionComponent";
import HelpSectionComponent from "../../components/HelpSectionComponent";
import OurFleetComponent from "../../components/OurFleetComponent";

import {request} from '../../services/BackendService';

import {Helmet} from "react-helmet";

import reviews from "../../common/Images/review-pickuphungary.png";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import TruncateComponent from "../../components/TruncateComponent";
import PickupServiceSteps from "../../components/PickupServiceSteps";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default class HomePage extends Component{

    constructor(props) {
        super(props);

        this.state = {
            reviews: []
        }
    }

    componentDidMount = () => {
        this.getReviews();
    }

    getReviews() {
        request('/getReviews').then(response => {
            this.setState({reviewsRaw: response.data});
            this.convertReviews();
        });
    }

    convertReviews() {
        let reviews = [];
        this.state.reviewsRaw.map(function (item, key) {
            let i = 0;
            let object = {};
            item.text.split('\n').map((text, key) => {
                // First three lines are Date, From and To
                // Then there should be two concecutive /n/n - second one is <hr />
                // Then the review itself

                i++;

                object.customerName = item.customerName;
                object.id = item.id;
                object.review = item.review;

                switch (i) {
                    case 1:
                        object.date = text;
                    case 2:
                        object.from = text;
                    case 3:
                        object.to = text;
                    case 5:
                        object.text = text;
                }
            })

            reviews.push(object);
        })

        this.setState({reviews});
    }

    render() {
        return(
            <div className="HomePage content">

                <Helmet>
                    <title>Book Airport Transfers in Hungary and Budapest | Best Price Guarantee</title>
                    <meta
                        name="description"
                        content="Book private transfer from airport or city in Hungary and Budapest, online. Reliable service, fixed prices, no hidden charges, Meet & Greet included."
                    />

                    <meta
                        name="keywords"
                        content="Book Transfer Budapest, Book transfer Hungary, Transfer Budapest, transfer hungary, Airport Transfer Budapest, taxi hungary, Taxi Budapest, Airport Pickup Budapest,  Get Airport Transfer Budapest, Airport to City Transfers Hungary, taxi service budapest"
                    />
                </Helmet>

                <SearchSectionComponent showInfo="true" />
                <div className="bg-gray pt-5 pb-5">
                    <div class="container-fluid">
                        {/* <div className="row">
                            <div className="col-md-6 col-12">
                                <h3 className="h4 text-uppercase">Safe & Clean</h3>
                                <p>
                                    We work together only with European Union's standard verified and approved
                                    drivers. We put safety and cleanliness first.
                                </p>
                            </div>
    
                            <div className="col-md-6 col-12">
                                <h3 className="h4 text-uppercase">Guarantee</h3>
                                <p>
                                    Price guarantee. Money back guarantee. We want you to leave Hungary knowing we did our 
                                    best to make you good memories.
                                </p>
                            </div>
                        </div>
                        <div className="row mt-5">
    
                            <div className="col-md-6 col-12">
                                <h3 className="h4 text-uppercase">Airport Meet and Greet</h3>
                                <p>
                                    We specialise in transfer from and to Budapest airport. We offer either on-call or meet & greet services.
                                </p>
                            </div>
    
                            <div className="col-12 col-md-6">
                                <h3 className="h4 text-uppercase">Fixed Prices</h3>
                                <p>
                                    Our prices are fixed. Does not matter the time or the season. You can book
                                    without the price-comparison hassle.
                                </p>
                            </div>
                            
                        </div> */}

                        <PickupServiceSteps />
                    </div>
                </div>
    
                <div className="HomePage__categories container-lg mt-5 mb-5">
                    <OurFleetComponent />
                </div>
    
                <div className="bg-gray pt-5 pb-5">
                    <div class="container-lg">
                        Each category mentioned above includes subcategories based on the number
                        of passengers. For example, our Economy Large People Carrier seats up to 9
                        person. To find out which car fits best to your needs, check out our <NavLink to="/booking">Booking</NavLink> page.
                    </div>
                </div>
    
                <div className="mt-5 homepage__reviews">
                    <h2 class="font-weight-bold text-center text-uppercase">Recent Reviews</h2>
                    
                    <div className="container-lg mt-5">

                        <div className="text-center">
                            <a href="https://www.trustpilot.com/review/pickuphungary.com" rel="noopener noreferrer" target="_blank" rel="noreferrer">
                                <img 
                                    src={reviews} 
                                    class="HomePage__reviews__img" 
                                    alt="Reviews of Pickup Hungary"
                                />
                            </a>
                        </div> 

                        <Carousel className="mt-5" responsive={responsive}>
                            {this.state.reviews.map((item, key) =>
                                <div className="col-11" key={key}>
                                    <div className="card">
                                        <div class="card-body">
                                            <h5 class="card-title">{item.customerName}'s Review</h5>
                                            <p className="card-text">
                                                    <span className="d-block">
                                                        {item.date}
                                                    </span>
                                                    <span className="d-block">
                                                        {item.from}
                                                    </span>
                                                    <span className="d-block">
                                                        {item.to}
                                                    </span>
                                                    <hr />
                                                    <span className="d-block">
                                                        <TruncateComponent length="300">
                                                            {item.text}
                                                        </TruncateComponent>
                                                    </span>
                                            </p>

                                            <p className="card-text">
                                                {
                                                    Array.from(Array(item.review).keys()).map(() => <i class="fa fa-duotone fa-star text-primary"></i>) 
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Carousel>
                    </div>
                </div>
                <HelpSectionComponent />
            </div>
        );
    }
}