import React from "react";

export const DestinationItem = ({item}) => {
    return (
        <div className="row">

            <div className="col-12">
                <h4 className="mt-5 text-uppercase font-weight-bold">{item.country}</h4>
            </div>
            
            <div className="col-md-4 col-6">
                <ul className="list-group list-group-flush">
                    {item.cities[0].map((city, key) =>
                        <li className="list-group-item">{city}</li>
                    )}
                </ul>
            </div>

            {item.cities[1] && <div className="col-md-4 col-6">
                <ul className="list-group list-group-flush">
                    {item.cities[1].map((city, key) =>
                        <li className="list-group-item">{city}</li>
                    )}
                </ul>
            </div>}

            {item.cities[2] && <div className="col-md-4 col-6">
                <ul className="list-group list-group-flush">
                    {item.cities[2].map((city, key) =>
                        <li className="list-group-item">{city}</li>
                    )}
                </ul>
            </div>}
        </div>
    );
}