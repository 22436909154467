import React from "react";

import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import SearchSectionComponent from "../../components/SearchSectionComponent";
import {FAQItem} from "./FAQItem";
import {request, post} from '../../services/BackendService';
import {Helmet} from "react-helmet";

export default class HelpCenterPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggled: '',
            faq: [],
            contactForm: {
                full_name: '',
                email_address: '',
                phone_number: '',
                message: '',
                booking_reference: ''
            },
            sent: false
        }
    }

    componentDidMount() {
        this.getFaq();
    }

    getFaq = async () => {
        const response = await request('/getFaq');
        this.setState({faq: response.data});
    }

    toggle = (key) => {
        if (this.state.toggled === key) {
            this.setState(
                {
                    ...this.state,
                    toggled: ''
                }
            )
            return;
        }

        this.setState(
            {
                ...this.state,
                toggled: key
            }
        )
    }

    handleContactFormChange = ({target}) => {
        this.setState({
            contactForm: {
                ...this.state.contactForm,
                [target.id]: target.value
            }
        });
    }

    submitContactForm = (event) => {
        event.preventDefault();
        
        if (event.target.checkValidity()) {
            event.target.classList.remove('was-validated');

            post('/sendEmail', this.state.contactForm).then(response => {
                this.setState({
                    ...this.state,
                    contactForm: {},
                    sent: true
                });
            }).catch((error) => {
                this.setState({
                    error: error.response.data.error.message
                })
            });
            
        } else {
            event.target.classList.add('was-validated');
        }
    }

    render() {
        return(
            <div className="HelpCenterPafe content">
                <Helmet>
                    <title>Help Center and FAQ | Pickup Hungary</title>
                    <meta
                        name="description"
                        content="Any questions about using Pickup Hungary, your booking or your ride? Pickup Hungary customer support is here to help you."
                    />

                    <meta
                        name="keywords"
                        content="FAQ about airport transfer, How to book transfer, What about luggage, need a child seat, Transfer booking, bulky luggage, flight is delayed, travelling with animal"
                    />
                </Helmet>
                
                <SearchSectionComponent />
    
                <div className="bg-gray">
                    <div className="container-lg pt-5 pb-5">
                        <h1 className="text-center text-uppercase font-weight-bold">Help Center</h1>
                    </div>
                </div>
    
                <div className="mt-5 mb-5 container-lg">
    
                    <h4 className="font-weight-bold mt-4 mb-4">Frequently Asked Questions</h4>
                    <ul class="no-style">
                        {this.state.faq.map((faq, key) =>
                            <FAQItem
                                toggled={this.state.toggled === faq.id} 
                                toggle={() => this.toggle(faq.id)} 
                                question={faq} 
                                key={key}
                            />
                        )}
                    </ul>
                
                </div>    
    
                <div className="bg-gray pt-5 pb-5">
                    <div className="container-lg">
                        <h4 className="font-weight-bold mt-4 mb-4">Contact Us</h4>
    
                        <p>
                            If you can’t find the answer for your question, you are more than welcome to contact us via one 
                            of the options below. We typically reply within 2 hours.
                        </p>

                        {this.state.error && <Alert variant="danger">
                            {this.state.error}
                        </Alert>}
    
                        {!this.state.sent && <form className="mt-3 needs-validation" noValidate onSubmit={this.submitContactForm}>
                            <div className="row gy-3">
                                <div className="col-md-6 col-12">
                                    <div className="form-group">
                                        <label for="full_name">Full Name</label>
                                        <Form.Control 
                                            type="text" 
                                            className="form-control" 
                                            id="full_name" 
                                            required
                                            onChange={this.handleContactFormChange}
                                            value={this.state.contactForm.full_name}
                                        />
                                    </div>
                                </div>
    
                                <div className="col-md-6 col-12">
                                    <div className="form-group">
                                        <label for="phone_number">Phone Number</label>
                                        <Form.Control 
                                            type="text" 
                                            className="form-control" 
                                            id="phone_number" 
                                            required
                                            onChange={this.handleContactFormChange}
                                            value={this.state.contactForm.phone_number}
                                        />
                                    </div>
                                </div>
    
                                <div className="col-md-6 col-12">
                                    <div className="form-group">
                                        <label for="booking_reference">Booking Reference (if you have one)</label>
                                        <Form.Control 
                                            type="text" 
                                            className="form-control" 
                                            id="booking_reference" 
                                            onChange={this.handleContactFormChange}
                                            value={this.state.contactForm.booking_reference}
                                        />
                                    </div>
                                </div>
    
                                <div className="col-md-6 col-12">
                                    <div className="form-group">
                                        <label for="email_address">E-mail Address</label>
                                        <Form.Control 
                                            type="email" 
                                            className="form-control" 
                                            id="email_address" 
                                            required
                                            onChange={this.handleContactFormChange}
                                            value={this.state.contactForm.email_address}
                                        />
                                    </div>
                                </div>
    
                                <div className="col-md-6 col-12">
                                    <div className="form-group">
                                        <label for="message">Message</label>
                                        <textarea 
                                            className="form-control" 
                                            id="message" 
                                            required
                                            onChange={this.handleContactFormChange}
                                            value={this.state.contactForm.message}
                                        />
                                    </div>
                                </div>
    
                                <div className="col-12">
                                    <button 
                                        type="submit" 
                                        className="btn btn-primary btn-lg" 
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                            
                        </form>}

                        {this.state.sent && 
                            <Alert variant="success">
                                <Alert.Heading>We got your message</Alert.Heading>
                                <p>
                                    We received your message, and will be back as soon as possible.<br />
                                    If your query is urgent, you might want to try calling us on the number below.
                                </p>
                            </Alert>}
    
                        <div className="mt-5">
                            <p class="h5 mb-4">Telegram / Viber / Whatsapp / Phone</p>
    
                            <p><i className="fa fa-phone" /> +36303510372</p>
                            <p><i className="fa fa-at" /> info@pickuphungary.com</p>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}