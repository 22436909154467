import React from "react";

export default function TruncateComponent(props) {

    const truncate = (str) => {
        return str.length > props.length ? str.substring(0, (props.length - 3)) + "..." : str;
    }

    return (
        <React.Fragment>
            {truncate(props.children)}
        </React.Fragment>
    );
};