import React from "react";

export default function ourFleet() {
    return (
        <React.Fragment>
            <div className="row mt-5">
                <div className="offset-md-1 col-md-6 col-lg-5 col-12">
                    <img src="/images/economy.svg" alt="Economy class car" />
                </div>
                <div className="col-md-4 col-12">
                    <h3 class="font-weight-bold">Entry Class</h3>
                    <p>Perfect for families and young individuals looking for the cheapest option.</p>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-4 col-12 d-block d-md-none">
                    <img src="/images/business.svg" alt="Business class car" />
                </div>
                <div className="offset-md-1 col-md-5 col-12">
                    <h3 class="font-weight-bold">Business Class</h3>
                    <p>Perfect for shorter and longer business trips. Includes services for your business use.</p>
                </div>
                <div className="col-md-4 col-12 d-none d-md-inline-block">
                    <img src="/images/business.svg" alt="Business class car" />
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-4 col-12 d-block d-md-none">
                    <img src="/images/premium.svg" alt="Premium class car" />
                </div>
                <div className="offset-md-1 col-md-5 col-12">
                    <h3 class="font-weight-bold">Premium Class</h3>
                    <p>If you are looking for a comfortable trip in a luxurious car, this option will suite
                    you the best.</p>
                </div>
                <div className="col-md-4 col-12 d-none d-md-inline-block">
                    <img src="/images/premium.svg" alt="Premium class car" />
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-6 col-lg-5 offset-md-1 col-12">
                    <img src="/images/group.svg" alt="Group class bus"/>
                </div>
                <div className="col-md-4 col-12">
                    <h3 class="font-weight-bold">For Groups</h3>
                    <p>Perfect for groups, for any route. Up to 49 people.</p>
                </div>
            </div>
        </React.Fragment>
    );
}