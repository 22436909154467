import React, { Component } from "react";
import { NavLink} from 'react-router-dom';

import HelpSectionComponent from "../../components/HelpSectionComponent";
import SearchSectionComponent from "../../components/SearchSectionComponent";
import {DestinationItem} from "./DestinationItem";

import {request} from "../../services/BackendService";
import {Helmet} from "react-helmet";

export default class DestinationsPage extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            destinations: []
        };
    }

    componentDidMount() {
        this.getDestinations();
    }

    getDestinations = () => {
        request('/getDestinations').then(response => {
            let destinations = response.data;

            destinations.forEach(element => {
                element.cities = this.chunkify(element.cities, 3);
            });

            this.setState({destinations: destinations});
        });
    }

    chunkify(a, n, balanced) {
    
        if (n < 2)
            return [a];
    
        var len = a.length,
                out = [],
                i = 0,
                size;
    
        if (len % n === 0) {
            size = Math.floor(len / n);
            while (i < len) {
                out.push(a.slice(i, i += size));
            }
        }
    
        else if (balanced) {
            while (i < len) {
                size = Math.ceil((len - i) / n--);
                out.push(a.slice(i, i += size));
            }
        }
    
        else {
    
            n--;
            size = Math.floor(len / n);
            if (len % size === 0)
                size--;
            while (i < size * n) {
                out.push(a.slice(i, i += size));
            }
            out.push(a.slice(size * n));
    
        }
    
        return out;
    }

    render() {
        return(
            <div className="DestinationsPage content">
                <Helmet>
                    <title>Destination Cities and Countries for Private Transfers</title>
                    <meta
                        name="description"
                        content="Private transfer services all around Hungary. Book your car, van or limousine with our online service. Meet & Greet Service at the Budapest airport."
                    />

                    <meta
                        name="keywords"
                        content="Pickup Hungary destinations, pickup hungary cities, transfer from budapest, transfer from vienna to hungary, transfer from heviz to hungary, transfer from Bratislava to hungary"
                    />
                </Helmet>
                <SearchSectionComponent />
                <div className="container-lg mb-5 mt-5">
                    <h1 className="text-uppercase text-center">Destinations</h1>

                    {this.state.destinations.map((item, key) =>
                        <DestinationItem item={item} key={item.id} />
                    )}
    
                    <div className="mt-3"></div>
                    
    
                    <div className="mt-5 pt-5 pb-5">
                        If you can not find your destination in the list, do  not worry. 
                        This probably means we haven’t added it to our database yet, and we don’t have a set price for it. 
                        You can still ask for a price quote when you book it on our <NavLink to="/booking">Booking page</NavLink>, and we will 
                        get back to you ASAP.
                    </div>
                </div>
                <HelpSectionComponent />
            </div>
        );
    }
}