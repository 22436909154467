import React from 'react';
import logo from '../../common/Images/logo.png';

import { Link } from 'react-router-dom';

import {Navbar, Nav} from 'react-bootstrap';
import { NavLinkComponent } from '../NavlinkComponent';

export default class HeaderComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
    }

    expand = () => {
        this.setState({
            expanded: !this.state.expanded
        });
    }
    
    render() {
        return(
            <div className="Header container-lg">
                <Navbar collapseOnSelect expand="lg" variant="light" expanded={this.state.expanded}>
                    <Navbar.Brand as={Link} to="/" href="/">
                        <img src={logo} alt="Pickup Hungary" className="Header__logo" />
                        <div className="sr-only">Go to the homepage</div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.expand} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto Header__navigation d-lg-none d-flex">
                            <NavLinkComponent expand={this.expand} />
                        </Nav>
                        <Nav className="ms-auto Header__navigation d-lg-block d-none">
                            <NavLinkComponent expand={null} />
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}