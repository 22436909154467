import React from "react";
export const FAQItem = ({question, toggled, toggle}) => {

    const convertMarkdownToHTMLWithLinks = (markdownContent) => {
        // Replace double underscores for bold text with HTML <strong> tags
        let htmlContent = markdownContent.replace(/__([^_]+)__/g, '<strong>$1</strong>');

        // Replace markdown links with HTML anchor tags
        htmlContent = htmlContent.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');

        // Return the formatted HTML content
        return htmlContent;
    };

    return (
        <React.Fragment>
            <ul className="mt-2 no-style">
                <li>
                    <button role="link" className="btn text-primary" onClick={toggle}>{question.name}</button>
                </li>
            { 
                toggled ? 
                    question.answer.split('\n').map((text, i) => {
                        return <li dangerouslySetInnerHTML={{ __html: convertMarkdownToHTMLWithLinks(text) }} />;
                    })
                : 
                    null 
            }
            </ul>
        </React.Fragment>
        
    );
}