export default class FormValidationService {
    constructor(rules) {
        this.inputs = [];
        this.valid = true;
        this.rules = rules;
        this.errors = [];
    }

    getRules() {
        return typeof this.rules === "undefined" ? {} : this.rules;
    }

    getInputs() {
        return typeof this.inputs === "undefined" ? {} : this.inputs;
    }

    assignRulesToInputs = () => {
        this.getInputs().forEach(element => {
            if (!(element.getName() in this.getRules()))
                return;

            element.type = this.getRules()[element.getName()].type;
            element.options = this.getRules()[element.getName()].options;
        });
    }

    addInput(input) {
        if (typeof input !== "object")
            return;

        this.inputs.push(input);
    }

    validate() {
        this.valid = true;

        if (Object.keys(this.getRules()).length === 0)
            return;

        this.assignRulesToInputs();

        this.getInputs().forEach(element => {
            // Check by element type
            switch (element.getType()) {
                default:
                    break;
            }

            // Check by options
            if (element.getOptions().indexOf('required') !== -1) {
                if (element.value === "") {
                    this.valid = false;
                    this.errors.push(element.name);
                }
            }
        });

        return this.valid;
    }
}