import React from 'react';

import {
    BookingPageStep1Template, 
    BookingPageStep2Template, 
    BookingPageStep3Template, 
    BookingPageStep4Template, 
    BookingPageInvalidStepTemplate
} from '../../templates/steps/index';

export default function BookingPageStepsComponent(props) {
    let step = props.step;

    switch (step) {
        case 1: 
            return (<BookingPageStep1Template {...props} />);
        case 2: 
            return (<BookingPageStep2Template {...props} />);
        case 3: 
            return (<BookingPageStep3Template {...props} />);
        case 4: 
            return (<BookingPageStep4Template {...props} />);
        default:
            return (<BookingPageInvalidStepTemplate />);
    }
};