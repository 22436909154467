import React from "react";

import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

export default function BookingPageNoResultsComponent({onSubmit, handleChange, noResults}) {
    return (
        <div className="BookingPage__box_small mb-5">
            <div className="BookingPage__box__content text-center">
                <h2 className="display-5 mb-4">
                    We couldn't find any matching routes
                </h2>

                <p>
                    We were trying to look, but none of our routes are matching what you are looking for. 
                    Give us your e-mail address below, and we  can give you a personalised offer in less than 24 hours.
                </p>

                <p>Just like always, the best price is guaranteed.</p>

                {noResults.sent && 
                    <Alert variant="success">
                        <Alert.Heading>We are finding you the best price for your route</Alert.Heading>
                        <p>
                            We received your request, and will be back as soon as possible.<br />
                            Thanks for your patience!
                        </p>
                    </Alert>}

                {!noResults.sent &&
           
                    <Form noValidate onSubmit={onSubmit}>

                        <Form.Group controlId="full_name" className="mt-4 mb-4">
                            <div class="sr-only">
                                <Form.Label>Full Name</Form.Label>
                            </div>

                            <div>
                                <Form.Control
                                    type="text"
                                    placeholder="Full Name"
                                    id="noResults.full_name"
                                    required
                                    onChange={handleChange}
                                />
                            </div>
                        </Form.Group>

                        <Form.Group controlId="email_address" className="mt-4 mb-4">
                            <div class="sr-only">
                                <Form.Label>E-mail Address</Form.Label>
                            </div>

                            <div>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail Address"
                                    id="noResults.email_address"
                                    required
                                    onChange={handleChange}
                                />
                            </div>
                        </Form.Group>

                        <div>
                            <button type="submit" className="btn btn-primary btn-lg">Request an offer</button>
                        </div>
                    </Form>

                }
           
            </div>
        </div>
    );
}