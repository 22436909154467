export const constants = {
    images: {
        economy: '/images/economy.svg',
        business: '/images/business.svg'
    },
    maxPassengers: 32
};

export const passengers = () => {
    let elements = [];

    for (var i = 1; i <= constants.maxPassengers; i++) {
        elements.push(i);
    }

    return elements;
};

export const phoneExtensions = [
    {label:"+1"},
     {label:"+880"},
     {label:"+32"},
     {label:"+226"},
     {label:"+359"},
     {label:"+387"},
     {label:"+681"},
     {label:"+590"},
     {label:"+673"},
     {label:"+591"},
     {label:"+973"},
     {label:"+257"},
     {label:"+229"},
     {label:"+975"},
     {label:"+267"},
     {label:"+685"},
     {label:"+599"},
     {label:"+55"},
     {label:"+375"},
     {label:"+501"},
     {label:"+7"},
     {label:"+250"},
     {label:"+381"},
     {label:"+670"},
     {label:"+262"},
     {label:"+993"},
     {label:"+992"},
     {label:"+40"},
     {label:"+690"},
     {label:"+245"},
     {label:"+502"},
     {label:"+30"},
     {label:"+240"},
     {label:"+590"},
     {label:"+81"},
     {label:"+592"},
     {label:"+594"},
     {label:"+995"},
     {label:"+44"},
     {label:"+241"},
     {label:"+503"},
     {label:"+224"},
     {label:"+220"},
     {label:"+299"},
     {label:"+350"},
     {label:"+233"},
     {label:"+968"},
     {label:"+216"},
     {label:"+962"},
     {label:"+385"},
     {label:"+509"},
     {label:"+36"},
     {label:"+852"},
     {label:"+504"},
     {label:"+58"},
     {label:"+970"},
     {label:"+680"},
     {label:"+351"},
     {label:"+47"},
     {label:"+595"},
     {label:"+964"},
     {label:"+507"},
     {label:"+689"},
     {label:"+675"},
     {label:"+51"},
     {label:"+92"},
     {label:"+63"},
     {label:"+870"},
     {label:"+48"},
     {label:"+508"},
     {label:"+260"},
     {label:"+212"},
     {label:"+372"},
     {label:"+20"},
     {label:"+27"},
     {label:"+593"},
     {label:"+39"},
     {label:"+84"},
     {label:"+677"},
     {label:"+251"},
     {label:"+252"},
     {label:"+263"},
     {label:"+966"},
     {label:"+34"},
     {label:"+291"},
     {label:"+382"},
     {label:"+373"},
     {label:"+261"},
     {label:"+590"},
     {label:"+212"},
     {label:"+377"},
     {label:"+998"},
     {label:"+95"},
     {label:"+223"},
     {label:"+853"},
     {label:"+976"},
     {label:"+692"},
     {label:"+389"},
     {label:"+230"},
     {label:"+356"},
     {label:"+265"},
     {label:"+960"},
     {label:"+596"},
     {label:"+222"},
     {label:"+256"},
     {label:"+255"},
     {label:"+60"},
     {label:"+52"},
     {label:"+972"},
     {label:"+33"},
     {label:"+246"},
     {label:"+290"},
     {label:"+358"},
     {label:"+679"},
     {label:"+500"},
     {label:"+691"},
     {label:"+298"},
     {label:"+505"},
     {label:"+31"},
     {label:"+47"},
     {label:"+264"},
     {label:"+678"},
     {label:"+687"},
     {label:"+227"},
     {label:"+672"},
     {label:"+234"},
     {label:"+64"},
     {label:"+977"},
     {label:"+674"},
     {label:"+683"},
     {label:"+682"},
     {label:"+225"},
     {label:"+41"},
     {label:"+57"},
     {label:"+86"},
     {label:"+237"},
     {label:"+56"},
     {label:"+61"},
     {label:"+242"},
     {label:"+236"},
     {label:"+243"},
     {label:"+420"},
     {label:"+357"},
     {label:"+61"},
     {label:"+506"},
     {label:"+599"},
     {label:"+238"},
     {label:"+53"},
     {label:"+268"},
     {label:"+963"},
     {label:"+599"},
     {label:"+996"},
     {label:"+254"},
     {label:"+211"},
     {label:"+597"},
     {label:"+686"},
     {label:"+855"},
     {label:"+269"},
     {label:"+239"},
     {label:"+421"},
     {label:"+82"},
     {label:"+386"},
     {label:"+850"},
     {label:"+965"},
     {label:"+221"},
     {label:"+378"},
     {label:"+232"},
     {label:"+248"},
     {label:"+7"},
     {label:"+65"},
     {label:"+46"},
     {label:"+249"},
     {label:"+253"},
     {label:"+45"},
     {label:"+49"},
     {label:"+967"},
     {label:"+213"},
     {label:"+598"},
     {label:"+262"},
     {label:"+961"},
     {label:"+856"},
     {label:"+688"},
     {label:"+886"},
     {label:"+90"},
     {label:"+94"},
     {label:"+423"},
     {label:"+371"},
     {label:"+676"},
     {label:"+370"},
     {label:"+352"},
     {label:"+231"},
     {label:"+266"},
     {label:"+66"},
     {label:"+228"},
     {label:"+235"},
     {label:"+218"},
     {label:"+379"},
     {label:"+971"},
     {label:"+376"},
     {label:"+93"},
     {label:"+354"},
     {label:"+98"},
     {label:"+374"},
     {label:"+355"},
     {label:"+244"},
     {label:"+54"},
     {label:"+61"},
     {label:"+43"},
     {label:"+297"},
     {label:"+91"},
     {label:"+994"},
     {label:"+353"},
     {label:"+62"},
     {label:"+380"},
     {label:"+974"},
     {label:"+258"}
];

export const countries = [
    {label:"Hungary"},
    {label:"Bangladesh"},
    {label:"Belgium"},
    {label:"Burkina Faso"},
    {label:"Bulgaria"},
    {label:"Bosnia and Herzegovina"},
    {label:"Barbados"},
    {label:"Wallis and Futuna"},
    {label:"Saint Barthelemy"},
    {label:"Bermuda"},
    {label:"Brunei"},
    {label:"Bolivia"},
    {label:"Bahrain"},
    {label:"Burundi"},
    {label:"Benin"},
    {label:"Bhutan"},
    {label:"Jamaica"},
    {label:"Bouvet Island"},
    {label:"Botswana"},
    {label:"Samoa"},
    {label:"Bonaire, Saint Eustatius and Saba{label:"},
    {label:"Brazil"},
    {label:"Bahamas"},
    {label:"Jersey"},
    {label:"Belarus"},
    {label:"Belize"},
    {label:"Russia"},
    {label:"Rwanda"},
    {label:"Serbia"},
    {label:"East Timor"},
    {label:"Reunion"},
    {label:"Turkmenistan"},
    {label:"Tajikistan"},
    {label:"Romania"},
    {label:"Tokelau"},
    {label:"Guinea-Bissau"},
    {label:"Guam"},
    {label:"Guatemala"},
    {label:"South Georgia and the South Sandwich Islands"},
    {label:"Greece"},
    {label:"Equatorial Guinea"},
    {label:"Guadeloupe"},
    {label:"Japan"},
    {label:"Guyana"},
    {label:"Guernsey"},
    {label:"French Guiana"},
    {label:"Georgia"},
    {label:"Grenada"},
    {label:"United Kingdom"},
    {label:"Gabon"},
    {label:"El Salvador"},
    {label:"Guinea"},
    {label:"Gambia"},
    {label:"Greenland"},
    {label:"Gibraltar"},
    {label:"Ghana"},
    {label:"Oman"},
    {label:"Tunisia"},
    {label:"Jordan"},
    {label:"Croatia"},
    {label:"Haiti"},
    {label:"Hong Kong"},
    {label:"Honduras"},
    {label:"Heard Island and McDonald Islands"},
    {label:"Venezuela"},
    {label:"Puerto Rico"},
    {label:"Palestinian Territory"},
    {label:"Palau"},
    {label:"Portugal"},
    {label:"Svalbard and Jan Mayen"},
    {label:"Paraguay"},
    {label:"Iraq"},
    {label:"Panama"},
    {label:"French Polynesia"},
    {label:"Papua New Guinea"},
    {label:"Peru"},
    {label:"Pakistan"},
    {label:"Philippines"},
    {label:"Pitcairn"},
    {label:"Poland"},
    {label:"Saint Pierre and Miquelon"},
    {label:"Zambia"},
    {label:"Western Sahara"},
    {label:"Estonia"},
    {label:"Egypt"},
    {label:"South Africa"},
    {label:"Ecuador"},
    {label:"Italy"},
    {label:"Vietnam"},
    {label:"Solomon Islands"},
    {label:"Ethiopia"},
    {label:"Somalia"},
    {label:"Zimbabwe"},
    {label:"Saudi Arabia"},
    {label:"Spain"},
    {label:"Eritrea"},
    {label:"Montenegro"},
    {label:"Moldova"},
    {label:"Madagascar"},
    {label:"Saint Martin"},
    {label:"Morocco"},
    {label:"Monaco"},
    {label:"Uzbekistan"},
    {label:"Myanmar"},
    {label:"Mali"},
    {label:"Macao"},
    {label:"Mongolia"},
    {label:"Marshall Islands"},
    {label:"Macedonia"},
    {label:"Mauritius"},
    {label:"Malta"},
    {label:"Malawi"},
    {label:"Maldives"},
    {label:"Martinique"},
    {label:"Northern Mariana Islands"},
    {label:"Montserrat"},
    {label:"Mauritania"},
    {label:"Isle of Man"},
    {label:"Uganda"},
    {label:"Tanzania"},
    {label:"Malaysia"},
    {label:"Mexico"},
    {label:"Israel"},
    {label:"France"},
    {label:"British Indian Ocean Territory"},
    {label:"Saint Helena"},
    {label:"Finland"},
    {label:"Fiji"},
    {label:"Falkland Islands"},
    {label:"Micronesia"},
    {label:"Faroe Islands"},
    {label:"Nicaragua"},
    {label:"Netherlands"},
    {label:"Norway"},
    {label:"Namibia"},
    {label:"Vanuatu"},
    {label:"New Caledonia"},
    {label:"Niger"},
    {label:"Norfolk Island"},
    {label:"Nigeria"},
    {label:"New Zealand"},
    {label:"Nepal"},
    {label:"Nauru"},
    {label:"Niue"},
    {label:"Cook Islands"},
    {label:"Kosovo"},
    {label:"Ivory Coast"},
    {label:"Switzerland"},
    {label:"Colombia"},
    {label:"China"},
    {label:"Cameroon"},
    {label:"Chile"},
    {label:"Cocos Islands"},
    {label:"Canada"},
    {label:"Republic of the Congo"},
    {label:"Central African Republic"},
    {label:"Democratic Republic of the Congo"},
    {label:"Czech Republic"},
    {label:"Cyprus"},
    {label:"Christmas Island"},
    {label:"Costa Rica"},
    {label:"Curacao"},
    {label:"Cape Verde"},
    {label:"Cuba"},
    {label:"Swaziland"},
    {label:"Syria"},
    {label:"Sint Maarten"},
    {label:"Kyrgyzstan"},
    {label:"Kenya"},
    {label:"South Sudan"},
    {label:"Suriname"},
    {label:"Kiribati"},
    {label:"Cambodia"},
    {label:"Saint Kitts and Nevis"},
    {label:"Comoros"},
    {label:"Sao Tome and Principe"},
    {label:"Slovakia"},
    {label:"South Korea"},
    {label:"Slovenia"},
    {label:"North Korea"},
    {label:"Kuwait"},
    {label:"Senegal"},
    {label:"San Marino"},
    {label:"Sierra Leone"},
    {label:"Seychelles"},
    {label:"Kazakhstan"},
    {label:"Cayman Islands"},
    {label:"Singapore"},
    {label:"Sweden"},
    {label:"Sudan"},
    {label:"Dominican Republic"},
    {label:"Dominica"},
    {label:"Djibouti"},
    {label:"Denmark"},
    {label:"British Virgin Islands"},
    {label:"Germany"},
    {label:"Yemen"},
    {label:"Algeria"},
    {label:"United States"},
    {label:"Uruguay"},
    {label:"Mayotte"},
    {label:"United States Minor Outlying Islands"},
    {label:"Lebanon"},
    {label:"Saint Lucia"},
    {label:"Laos"},
    {label:"Tuvalu"},
    {label:"Taiwan"},
    {label:"Trinidad and Tobago"},
    {label:"Turkey"},
    {label:"Sri Lanka"},
    {label:"Liechtenstein"},
    {label:"Latvia"},
    {label:"Tonga"},
    {label:"Lithuania"},
    {label:"Luxembourg"},
    {label:"Liberia"},
    {label:"Lesotho"},
    {label:"Thailand"},
    {label:"French Southern Territories"},
    {label:"Togo"},
    {label:"Chad"},
    {label:"Turks and Caicos Islands"},
    {label:"Libya"},
    {label:"Vatican"},
    {label:"Saint Vincent and the Grenadines"},
    {label:"United Arab Emirates"},
    {label:"Andorra"},
    {label:"Antigua and Barbuda"},
    {label:"Afghanistan"},
    {label:"Anguilla"},
    {label:"U.S. Virgin Islands"},
    {label:"Iceland"},
    {label:"Iran"},
    {label:"Armenia"},
    {label:"Albania"},
    {label:"Angola"},
    {label:"Antarctica"},
    {label:"American Samoa"},
    {label:"Argentina"},
    {label:"Australia"},
    {label:"Austria"},
    {label:"Aruba"},
    {label:"India"},
    {label:"Aland Islands"},
    {label:"Azerbaijan"},
    {label:"Ireland"},
    {label:"Indonesia"},
    {label:"Ukraine"},
    {label:"Qatar"},
    {label:"Mozambique"}
];

export const times = [
    {label: '00:00'},
    {label: '00:30'},
    {label: '01:00'},
    {label: '01:30'},
    {label: '02:00'},
    {label: '02:30'},
    {label: '03:00'},
    {label: '03:30'},
    {label: '04:00'},
    {label: '04:30'},
    {label: '05:00'},
    {label: '05:30'},
    {label: '06:00'},
    {label: '06:30'},
    {label: '07:00'},
    {label: '07:30'},
    {label: '08:00'},
    {label: '08:30'},
    {label: '09:00'},
    {label: '09:30'},
    {label: '10:00'},
    {label: '10:30'},
    {label: '11:00'},
    {label: '11:30'},
    {label: '12:00'},
    {label: '12:30'},
    {label: '13:00'},
    {label: '13:30'},
    {label: '14:00'},
    {label: '14:30'},
    {label: '15:00'},
    {label: '15:30'},
    {label: '16:00'},
    {label: '16:30'},
    {label: '17:00'},
    {label: '17:30'},
    {label: '18:00'},
    {label: '18:30'},
    {label: '19:00'},
    {label: '19:30'},
    {label: '20:00'},
    {label: '20:30'},
    {label: '21:00'},
    {label: '21:30'},
    {label: '22:00'},
    {label: '22:30'},
    {label: '23:00'},
    {label: '23:30'}
];