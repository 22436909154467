import React from 'react';
import { passengers } from '../../constants';
import InputGroup from './InputGroup';

export default function BookingPageSearchSectionComponent({handleChange, draft, validateSearch, errors, handleAutocompleteSelect}) {
    return(
        <div className="BookingSearchSection">
            <div className="BookingSearchSection__layer">
                <div className="container">
                    <div className="row">
                        <div className="col-12 BookingSearchSection__h1 mb-4">
                            <h1>Booking</h1>
                        </div>
                        <form className="col-12 BookingSearchSection__form" onSubmit={validateSearch} noValidate>
                            
                            <div className="d-none d-lg-block">
                                <div className="input-group input-group-lg">
                                    <InputGroup 
                                        handleChange={handleChange} 
                                        draft={draft} 
                                        errors={errors}
                                        passengers={passengers}
                                        handleAutocompleteSelect={handleAutocompleteSelect}
                                        />
                                </div>
                            </div>
                            

                            <div className="d-flex flex-column gap-3 d-lg-none">
                                <InputGroup 
                                    handleChange={handleChange} 
                                    draft={draft} 
                                    errors={errors}
                                    passengers={passengers}
                                    handleAutocompleteSelect={handleAutocompleteSelect}
                                    />
                            </div>
                            

                            <div className="block mt-2 mb-2 text-white form-check">
                                <input 
                                    type="checkbox" 
                                    className="mr-2 form-check-input"
                                    id="draft.return_journey_checkbox"
                                    checked={draft.return_journey_checkbox}
                                    onChange={handleChange}
                                /> Return Journey
                            </div>

                            <button 
                                type="submit"
                                className="btn btn-primary btn-lg mt-3 BookingSearchSection__form__submit"
                            >
                                Search
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
    );
}