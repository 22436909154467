import React from 'react';
import { NavLink} from 'react-router-dom';

export default function HelpSectionComponent(props) {

    return(
        <div className="HelpSection">
            <div className="row-flex">
                <div className="col-md-8 offset-md-2 col-12">
                    <p className="h5 font-weight-bold">
                        For help, frequently asked questions, please visit our help center.
                    </p>

                    <NavLink to="/help-center" className="btn btn-lg btn-primary mt-5">
                        Help Center
                    </NavLink>
                </div>
            </div>
        </div>
    );
}