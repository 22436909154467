import React from 'react';
import { NavLink } from 'react-router-dom';

export const NavLinkComponent = ({expand}) => (
    <React.Fragment>
        <NavLink
            className="Header__navigation__link"
            to="/" 
            href="/"
            exact
            activeClassName="active"
        >
            <span onClick={expand}>
                HOME
            </span>
        </NavLink>
        <NavLink 
            className="Header__navigation__link"
            to="/about" 
            href="/about"
            activeClassName="active"
        >
            <span onClick={expand}>
                ABOUT
            </span>
        </NavLink>

        <NavLink
            className="Header__navigation__link" 
            to="/booking" 
            href="/booking"
            activeClassName="active"
        >
            <span onClick={expand}>
                BOOKING
            </span>
        </NavLink>

        <NavLink 
            className="Header__navigation__link"
            to="/destinations" 
            href="/destinations"
            activeClassName="active"
        >
            <span onClick={expand}>
                DESTINATIONS
            </span>
        </NavLink>

        <a 
            className="Header__navigation__link"
            href="/blog"
            activeClassName="active"
        >
            <span onClick={expand}>
                BLOG
            </span>
        </a>

        <NavLink
            className="Header__navigation__link"
            to="/help-center" 
            href="/help-center"
            activeClassName="active"
        >
            <span onClick={expand}>
                <i fa className="fa fa-solid fa-circle-question"></i> HELP
            </span>
        </NavLink>
    </React.Fragment>
);