import React, {useState, Component} from "react";
import { Route, BrowserRouter as Router, withRouter, Switch} from "react-router-dom";
import {Helmet} from "react-helmet";

import HeaderComponent from "./components/HeaderComponent";
import ScrollToTopComponent from "./components/ScrollToTopComponent";
import FooterComponent from "./components/FooterComponent";
import CoronaVirusUpdateComponent from "./components/CoronaVirusUpdateComponent";
import ExitModalComponent from "./components/ExitModalComponent";
import PromotionBarComponent from "./components/PromotionBarComponent";

import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import BookingPage from "./pages/BookingPage";
import DestinationsPage from "./pages/DestinationsPage";
import HelpCenterPage from "./pages/HelpCenterPage";
import BookingSummaryPage from "./pages/BookingSummaryPage";
import AirportTransfer from "./pages/AirportTransfer";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import GroupTransferPage from "./pages/GroupTransferPage";
import PremiumTransferPage from "./pages/PremiumTransferPage";
import CoronaVirusUpdatePage from "./pages/CoronaVirusUpdatePage";
import NotFoundPage from "./pages/NotFoundPage";

import "./index.scss";

const HeaderComponentWithRoute = withRouter(props => <HeaderComponent {...props}/>);
const ScrollToTopComponentWithRoute = withRouter(props => <ScrollToTopComponent {...props}/>);
const ExitModalComponentWithRoute = withRouter(props => <ExitModalComponent {...props}/>);

export default class Routes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mouseOut: false
        }
    }

    render() {
        return (
            <Router>
                <Helmet>
                    <title>Welcome to Pickup Hungary - Private transfer in Hungary</title>
                </Helmet>

                <ScrollToTopComponentWithRoute />
                <div className="main" onMouseLeave={() => {if (!this.state.mouseOut) { this.setState({mouseOut: true});}}}>
                    {/* { this.state.mouseOut ? <ExitModalComponentWithRoute /> : null} */}
                    {/*<PromotionBarComponent />*/}
                    {/*<CoronaVirusUpdateComponent />*/}
                    <HeaderComponentWithRoute />
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route exact path="/about" component={AboutPage} />
                        <Route exact path="/booking/:step?" component={BookingPage} />
                        <Route exact path="/destinations" component={DestinationsPage} />
                        <Route exact path="/help-center" component={HelpCenterPage} />
                        <Route exact path="/bookingSummary/:bookingReference" component={BookingSummaryPage} />
                        <Route exact path="/budapest-airport-transfer" component={AirportTransfer} />
                        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
                        <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
                        <Route exact path="/group-transfer-hungary" component={GroupTransferPage} />
                        <Route exact path="/premium-transfer-airport" component={PremiumTransferPage} />
                        <Route exact path="/covid-19-safety-measures" component={CoronaVirusUpdatePage} />
                        <Route component={NotFoundPage} status={404} />
                    </Switch>
                    <FooterComponent />
                </div>
            </Router>
        );
    }
};