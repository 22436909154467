import React, {useState} from 'react';
import { NavLink} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ExitModalComponent(props) {

    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);

    return (
        <Modal show={show} onHide={handleClose} className="ExitModal">
            <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                
                    <p className="ExitModal__firstLine">Low Season Sale</p>
                    <p className="ExitModal__secondLine">SAVE 15%</p>
                    <p className="ExitModal__thirdLine">Use Code: LOWSEASON</p>
                
                </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} variant="secondary" className="btn-lg">
                    Close
                </Button>
                
                <NavLink to="/booking" onClick={handleClose} className="btn btn-primary btn-lg">
                    GO TO BOOKING
                </NavLink>
            </Modal.Footer>
        </Modal>
    );

}