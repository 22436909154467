import React from 'react';
import { NavLink} from 'react-router-dom';

export default function FooterComponent(props) {

    return(
        <footer className="Footer">
            <div className="Footer__top">
                <div className="container-lg">
                    <div class="row">
                        <div className="col-md-4 col-6">
                            <h5>Services</h5>
                            <ul className="no-style">
                                <li>
                                    <NavLink to="/budapest-airport-transfer">Budapest Airport Transfer</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/booking#city-to-city-transfer">City to City Transfer</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/group-transfer-hungary">Group Transfer</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/premium-transfer-airport">Premium Transfer & Premium Airport Transfer</NavLink>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-4 col-6">
                            <h5>Sitemap</h5>
                            <ul className="no-style">
                                <li>
                                    <NavLink to="/about" className="Footer__NavLink">About Us</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/destinations" className="Footer__NavLink">Destinations</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/help-center" className="Footer__NavLink">Contact</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/booking" className="Footer__NavLink">Booking</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/help-center" className="Footer__NavLink">Frequently Asked Questions</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/privacy-policy" className="Footer__NavLink">Privacy Policy</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/terms-and-conditions" className="Footer__NavLink">Terms and Conditions</NavLink>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-4 col-12">
                            <h5>Contact Us</h5>
                            <ul className="Footer__top__contact no-style">
                                <li>
                                    <i class="fa-sharp fa-regular fa-phone me-2"></i>
                                    +36303510372
                                </li>
                                <li>
                                    <a href="mailto:info@pickuphungary.com">
                                        <i className="fa-sharp fa-regular fa-at me-2" /> 
                                        info@pickuphungary.com
                                    </a>
                                </li>
                                <li>
                                    <i className="fa-sharp fa-regular fa-map-marker me-2" />
                                    Vak Bottyan utca 32., 8600 Siofok, Hungary
                                </li>
                            </ul>

                            <ul className="Footer__top__social_media no-style">                                
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Pickup-Hungary-101550984884677" title="Pickup Hungary on Facebook" rel="noreferrer">
                                        <i className="fa-brands fa-facebook me-2" /> Find us on Facebook
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div className="col-md-8 col-12">
                            <h5>How to</h5>
                            <ul className="no-style">
                                <li>
                                    <a href="https://pickuphungary.com/blog/how-to-get/budapest-airport-to-heviz/">How to get from Budapest Airport to Hévíz</a>
                                </li>
                                <li>
                                    <a href="https://pickuphungary.com/blog/how-to-get/budapest-airport-to-debrecen/">How to get from Budapest Airport to Debrecen</a>
                                </li>
                                <li>
                                    <a href="https://pickuphungary.com/blog/how-to-get/budapest-airport-to-budapest-center/">How to get from Budapest Airport to Budapest Center</a>
                                </li>
                                <li>
                                    <a href="https://pickuphungary.com/blog/how-to-get/from-budapest-airport-to-budapest-parliament/">How to get from Budapest Airport to Budapest Parliament (Országház)</a>
                                </li>
                                <li>
                                    <a href="https://pickuphungary.com/blog/how-to-get/from-budapest-airport-to-aquaworld-budapest/">How to get from Budapest Airport to Aquaworld Budapest</a>
                                </li>
                                <li>
                                    <a href="https://pickuphungary.com/blog/how-to-get/from-budapest-airport-to-buda-castle/">How to get from Budapest Airport to Buda Castle</a>
                                </li>
                                <li>
                                    <a href="https://pickuphungary.com/blog/how-to-get/from-budapest-airport-to-balaton/">How to get from Budapest Airport to Lake Balaton</a>
                                </li>
                                <li>
                                    <a href="https://pickuphungary.com/blog/how-to-get/from-budapest-airport-to-siofok/">How to get from Budapest Airport to Siófok</a>
                                </li>
                                <li>
                                    <a href="https://pickuphungary.com/blog/how-to-get/from-budapest-airport-to-balatonfured/">How to get from Budapest Airport to Balatonfüred</a>
                                </li>
                                <li>
                                    <a href="https://pickuphungary.com/blog/how-to-get/from-budapest-airport-to-keszthely/">How to get from Budapest Airport to Keszthely</a>
                                </li>
                                <li>
                                    <a href="https://pickuphungary.com/blog/how-to-get/from-budapest-airport-to-hajduszoboszlo/">How to get from Budapest Airport to Hajdúszoboszló</a>
                                </li>
                                <li>
                                    <a href="https://pickuphungary.com/blog/how-to-get/budapest-airport-to-szeged/">How to get from Budapest Airport to Szeged</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-12">
                            <h5>Company Information</h5>
                            <ul className="no-style">
                                <li>Spa Thermal Travel Kft.</li>
                                <li>Mailing Address: 8600 Siófok, Vak Bottyán utca 32., Hungary</li>
                                <li>Company Registration Number: 14 09 314248</li>
                                <li>VAT Number: HU24958952</li>
                                <li>License Number: U-001568</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div class="col-12">
                            <img 
                                src="https://docs.barion.com/images/6/69/Barion-card-payment-mark-2017-400px.png" 
                                alt="Accepted payment methods: Visa, Mastercard, Maestro, Amex" 
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="Footer__bottom py-2">
                <div className="container-lg">
                    <div className="row">
                            <div className="col-md-6 col-12">
                                <i class="fa-regular fa-copyright me-2"></i> Copyright 2020 - 2024, SPA Thermal Travel Hungary Kft.
                            </div>

                            <div className="col-md-6 col-12 text-md-end">
                                <a href="http://devology.io" class="text-primary" target="_blank" rel="noopener noreferrer">
                                    DEV with <i class="fa-solid fa-heart mx-1 text-danger"></i> by DEVOLOGY.IO Limited (London, GB)
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        </footer>
    );
}