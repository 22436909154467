import React, { Component } from "react";

import {Helmet} from "react-helmet";

export default class TermsAndConditionsPage extends Component {
    render() {
        return (
            <div className="LandingPage content">
                <Helmet>
                    <title>Terms and Conditions</title>
                    <meta
                        name="description"
                        content="Pickup Hungary terms and conditions"
                    />
                </Helmet>

                <div className="container mt-4 mb-5">
                        <h1>Terms and Conditions</h1>

                        <p>
                            Spa thermal Travel Kft. (registered office: Hungary, 8600 Siofok, Vak Bottyan st. 32. Hungary; company registration number: 14-09-314248, telephone number: +36 303510369, email address: info@pickuphungary.com, hereinafter: „ Supplier”) hereby releases its general terms and conditions („GTC”) of using its transfer services offered on its home page under www.pickuphungary.com („Website”).<br />
                            These terms and conditions and the online booking form constitute the entire Agreement concerning the provision of transfer service (“Supplier”) between you and www.pickuphungary.com. Completion of the booking form and use of the Service indicates your unconditional acceptance of the terms and conditions set out in this Agreement.
                        </p>

                        <h2 class="h3">Terms used in this GTC</h2>

                        <p>
                            “Passenger” can be one or more persons traveling in a Transfer vehicle to/from “A” location to/from “B” location a specific destination<br />
                            “Transfer” is the general name given to the pre-booked transportation of Passengers to/from a destination including airports, railway stations, cities, and towns, or other destinations stipulated by Passenger.<br />
                            “Reservation Voucher” a document that confirms Passenger reservation, showing all the details of the Transfer.<br />
                            Paid” means the Transfer is paid for in advance by Bank Card. A booking fee is normally charged for this service.<br /><br />
                            
                            <b>Vehicle types:</b><br />
                            
                            - Standard car 4 pax 3 bags (Hatchback, Sedan, Estate) eg. Skoda Octavia, Ford Focus<br />
                            - People Carrier 4 pax 4 bags, Renault Scenic<br />
                            - Large People Carrier,  8 pax 8 bags, eg. Opel Vivaro, Renault Traffic<br />
                            - Business car 3 pax 3 bags, Mercedes E-class, VW Arteon<br />
                            - Premium 3 pax 3 bags, Mercedes S-class<br />
                            - Business Lárge People Carrier  6 pax 4 bags, Mercedes V Class, Mercedes Viano<br />
                            - Minibus 16 pax 16 bags eg. Mercedes Sprinter<br />
                            - Minibus long type 19 pax 19 bags eg. Mercedes Sprinter long type, Iveco<br />
                            - Coaches 49 pax 49 bags eg.Mercedes-Benz Travego, Scania<br />
                        </p>

                        <h2 class="h3">Description of the services</h2>

                        <p>
                            The Supplier offers Transfer services from/ to Budapest to any Hungarian cities and to Austria, Slovakia, Slovenia, Croatia as well. Door to door service with professional drivers. 
                        </p>

                        <h2 class="h3">Pre-contractual information</h2>

                        <p>
                            All information on the Transfer services provided on the Website and all other information in this GTC forms part of the information on the services.
                            By sending the order the Passenger confirms that he/she has read, understood and accepted the pre-contractual information on the services.
                        </p>

                        <h2 class="h3">The conclusion of the contract</h2>

                        <h3 class="h4">Concluding the contract via electronic means</h3>

                        <p>
                            The contract is concluded by filling and sending to the Supplier the electronic application form accessible through the online reservation system on the Website, which contains all the relevant information on the Transfer service and the Passenger and allows to acknowledge and accept this GTC and Privacy Policy. The supplier confirms the order by sending the Reservation Voucher by means of which the contract is concluded.<br />
                            During the conclusion of the contract via electronic means it is possible for the Passenger to fully learn the detailed description of the Transfer services, the content of the contract, and this GTC before sending the order and to confirm that this happened before sending the order, furthermore to get information on the technical steps how to conclude the contract via electronic means.
                        </p>

                        <h3 class="h4">Technical steps to conclude the contract</h3>

                        <p>
                            a) The Passenger shall choose the places and date of the Transfer, the number of the Passengers, the type of the Transfer on the Website;<br />
                            b) The Passenger shall provide personal data (name, email address, phone number), flight details, the intended destination and other needs by filling the electronic application form accessible on the Website;<br />
                            You must order a suitable car size for the number of passengers and luggage. The Supplier cannot guarantee to carry excessive amounts of luggage. Please note that a child, no matter what age, counts as one passenger.
                            If you need to transport a wheelchair please specify this at the time of booking.<br />
                            c) The system carries out a prior calculation of the fee payable by the Passenger;<br />
                            d) The system checks all the possible input errors, missing data and provides help to identify and correct these data thereof, the Passenger shall correct or complete these data;<br />
                            f) Passenger certifies that he/she has read, understood and accepted this GTC and the Privacy Policy by ticking the button “Pay” <br />
                        </p>

                        <h3 class="h4">Reservation made by a third party on behalf of the Passenger</h3>

                        <p>
                            A Reservation can be made on behalf of the Passenger by a third party (e.g. travel agent, airline, tourist center, etc.) but the agreement that comes into existence in this way binds the Passenger as if he had accepted the terms and conditions himself.<br />
                            If a Transfer was reserved by a third party Supplier shall not examine whether the third party is lawfully represents the Passengers and or if the provided data is accurate.<br />
                            In this case the third party shall ensure the notification of the Passengers, including their rights and obligations and the third party shall be liable for the consequences resulting from the failure to do so.<br />
                            Rights and obligations of the Passenger entitles the third party who made the reservation on behalf of the Passenger until the start of the journey excluding obligations which due to their nature can only affect the Passenger himself.<br />
                            The Supplier fulfills its obligation to give statements and to send documents based on the contract to the name, address, e-mail address and other contact details provided by the Passenger. The client shall inform the Supplier in writing if there is a change in any of the provided contact details, the client shall be responsible for any failure or delay in doing so.
                        </p>

                        <h3 class="h4">Other Rules of Reservation</h3>

                        <p>
                            All prices displayed on the website are provided per car. The price does not depend on the number of passengers within the car capacity. Important note: maximum capacity of a car is specified for each class.<br />
                            You can make a booking not less than 24 hours before the trip.<br />
                            It is the responsibility of Passengers (or any third party acting on their behalf) to ensure that all the personal details are given during the booking process, e.g. names of the Passengers, name of the airport, flight details, dates and times the service is required, e-mail addresses, etc. are accurate. The Supplier cannot accept any liability for non-delivery of the service or subsequent losses due to errors made at the booking stage.<br />
                            The Reservation should clearly indicate the number of bags to be carried on the Transfer. A Passenger’s normal allowance is one case and one item of hand luggage. Any additional bags above the permitted amount must be paid for. (See Baggage below) It is the responsibility of the Passenger to order the correct size of vehicle that can accommodate all the luggage required. It is not possible for a Driver to carry extra baggage that is not shown on the Reservation Voucher on the day of the Transfer service itself.<br />
                            The confirmation e-mail showing the details of the Reservation and the payment should be shown to the Driver of the Transfer service prior to boarding the car, minibus, coach or bus provided (or another form of transport). Failure to do so will mean that the Driver has the right to refuse to carry out the Transfer.
                            Children under the age of 14 cannot use the service unless accompanied by a person aged 16 or over.
                        </p>

                        <h2 class="h3">The subject of contract</h2>

                        <p>
                            All information on the Transfer service which is the subject of the contract and the terms and conditions of its use are contained in the description published on the Website and in this GTC.
                            If the Passenger has a special request with respect to the Transfer service ordered, this shall become part of the contract only if the special request has been notified in advance, the Supplier has undertaken to perform it and the special request has been explicitly recorded in the confirmation email sent to the Passenger.
                        </p>

                        <h2 class="h3">Payment terms</h2>

                        <h3 class="h4">The transfer fee</h3>

                        <p>
                            Based on the concluded contract, the Passenger is obliged to pay the full fee specified in the contract.<br />
                            The rates are inclusive of all taxes at the time of the booking.<br />
                            All payments must be made in Euro<br />
                        </p>

                        <h3 class="h4">Payment method</h3>

                        <p>
                            Prepayment by Credit or Debit Card via the Barion system<br />

                            Payment by a valid credit or Debit Card is required at the time of Reservation. The Passenger will be redirected to a payment page with a secure communication channel. <br />
                            
                            Online bank card payments are executed via the Barion system. The merchant does not get and store bank card data. Barion Payment Inc., the provider of this service, is an institution under the authority of the Central Bank of Hungary, its license number is: H-EN-I-1064/2013.<br />
                            The content of the payment page is encrypted and protected, and Supplier is not aware of its content. During the transaction, the card number, card expiry date and the 3-digit validation code on the back of the card at the end of the signature line must be provided.<br />
                            After the initiation of the transaction, the card is subject to real-time authorization, whereby the authenticity of the card details, coverage and purchase limit are verified by the card-issuing bank.<br />
                            If the card is rejected or payment refused, Passenger will be asked to provide another credit card as guarantee. Without a valid credit or debit card, the Reservation will be automatically cancelled.<br />
                            Passenger Reservation Voucher will show the journey details, number of passengers and bags together with the payment made. This must be shown to the Driver before the Transfer takes place.<br />
                            The Driver can refuse to carry out the Transfer if Passenger are unable to produce the Reservation Voucher at his discretion. There is no refund if this is the case.
                        </p>

                        <h2 class="h3">Modification and cancellation of the contract</h2>

                        <p>
                            The Passenger has the right to modify or cancel Passenger Reservation up to 24 hours before the date of the Transfer without charge.  <br />
                            All changes are subject of Supplier confirmation and availability. Supplier reserve the right to cancel the booking after the change is done if there is no possibility to accept the requested change.<br />
                            If the price of the new transfer is higher than the amount paid, the customer will be charged for the difference. If the price of the new transfer is lower than the amount paid, the difference will be refunded.
                        </p>

                        <h2 class="h3">The performance of the contract</h2>

                        <h3 class="h4">Conditions of the performance of Supplier Transfer services</h3>

                        <p>
                            The transfer vehicle will arrive at the pick-up point not later than 5 minutes of the time and date and at the specified address confirmed in the Reservation Voucher at the time of booking.<br />
                            However, if the Driver is unable to reach any drop-off or pick-up point due to traffic jams, road closures or other traffic restrictions, the Driver will drop-off or pick-up the Passenger(s) at the nearest point to the destinations given in the Reservation Voucher. <br />
                            The Driver cannot be expected to violate traffic rules because of such incidences even if it means the Passenger arrives late at the drop-off point.<br />
                            The Driver will use its best endeavours to make sure that the pick-up vehicle arrives at the exact flight arrival time even where the flight arrival is delayed. <br />
                            The Passenger must ensure that they arrive at the airport a minimum of 2 hours before their scheduled flight time. You are responsible for ensuring that the transfer service is reserved at a date and time, which meets your itinerary requirements.<br />
                            If the Pick up point is at an airport, the driver will wait in the Arrivals Hall with a name board showing the Passenger’s name. <br />
                            The Driver will wait for Passenger up to 1 hour after the Passenger has landed. After that, the Reservation is regarded as a “No-show”.<br /><br />
                            
                            If the Pick up point is a hotel or address, includes a 15 minute waiting period
                        </p>

                        <h3 class="h4">Terms and Conditions common to all types of Transfer</h3>

                        <p>
                            The Passenger should be in a suitable state for traveling. In cases where the Passenger is under the influence of alcohol or narcotics or suffers from an infectious or contagious disease, or who wears clothes or has luggage in an exceptionally dirty condition, the driver has the right to refuse entry into the Transfer vehicle. No refund will be given.
                            Smoking is not permitted in or around the vehicle in accordance with the Hungarian law.
                            It is at the discretion of the company to decline any booking and to request any passenger to leave the vehicle if his/her conduct seems to be of discomfort to other passengers or to the driver.
                            
                            Live animals can travel in any form of Transfer but only in suitable carrying equipment used in air transportation (cage, box). The animal should not emit an offensive smell or harass other Passengers during the travel. Payment is required in advance for the transportation of animals but a guide-dog will be transported free of charge.
                            Bicycles, golf bags and ski equipment will be carried providing that the appropriate fee has been paid at the time of making the reservation.
                            If a Passenger needs a wheelchair for mobility, the chair will be carried free of charge providing that it can be folded and the Supplier is notified in advance when the Passenger makes the reservation. Only 1 wheelchair per Passenger can be transported free of charge.
                            One pushchair/pram per Passenger can be transported free of charge<br /> 
                            It is forbidden to take into in the pickup vehicle or car:<br /> 
                            a) Hazardous or inflammable materials (e.g. acids, petroleum products, toxic or radioactive materials, etc.).<br /> 
                            b) Firearms or other weapons cold steel unless contained in a protective cover and locked in the luggage compartment of the pickup vehicle or car.<br /> 
                            c) Objects that are repulsive or offensive to others.<br /> 
                            d) Objects that can jeopardize the physical health of other Passengers or cause physical damage or contaminate the pickup vehicle or car.<br /> 
                            e) An object unsuitable for transportation by car (vehicle) due to its shape, volume or nature.<br /> 
                        </p>

                        <h3 class="h4">Safety Considerations</h3>

                        <p>
                            Child Seats<br />
                            
                            The law requires a child seat to be used for child passengers, the Supplier is only required to provide a suitable child seat where this has been ordered in advance during the reservation process.<br />
                            If no such order has been made, the Driver can refuse to carry the child. No transfer fees will be refunded nor will any claim for additional expenses incurred be allowed.<br />
                            One child seat is provided free of charge.<br />
                            If you are traveling with several children, then the second and subsequent child seats are provided for extra fee: 5 euros / chair.<br /><br />
                            
                            Wearing of Seat Belts<br />
                            EU laws require passengers to wear a seat belt, they must be worn when requested to do so by the Driver. If they fail to do so when such a request is made, the driver has the right to refuse to carry out the transfer. No transfer fees will be refunded nor will any claim for additional expenses incurred be allowed.
                        </p>

                        <h3 class="h4">Conditions relating to the luggage</h3>

                        <p>
                            Passenger luggage should comply with generally accepted airport standards, that is, one case weighing not more than 32 Kg; and one item of hand luggage no more than 10 Kg. This is included in Passenger Transfer fee.<br />
                            For standard luggage the sum of three dimensions (length, width, and height) does not exceed 158 cm. Any bigger baggage can be considered non-standard.<br /><br />
                            
                            Passenger luggage should not be unusually large so that it interferes with the loading of luggage belonging to other Passengers.<br />
                            A charge is made for each item of extra luggage beyond the basic luggage allowance permitted with the Reservation. Extra luggage which has not been paid for cannot be put into the Transfer vehicle; neither can the Driver be persuaded to take an extra fee for doing so.<br />
                            The Supplier will not be responsible for any damage to or loss of personal property carried within the luggage area or within the passenger area of the vehicle. The carriage of luggage depends solely at the Passenger’s risk. Though we provide an estimation on the amount of luggage to be carried for transfers, we are restricted on luggage compartment sizes. In accordance to obvious safety reasons we strictly forbid to load the luggage in the aisle of the coach or on passenger seats of the car. If the need arises for the arrangement of a larger vehicle for the luggage in excess of notification the client will be charged accordingly. <br />
                            Please note this does not necessarily equate with the number of persons carried.<br />
                            Before putting Passenger luggage into the Transfer vehicle, the Driver will inspect it to determine its condition and suitability for carrying. Any damage or defects will be pointed out to the Passenger to avoid disputes at a later date. 
                        </p>

                        <h3 class="h4">Delays, cancelled or diverted flights</h3>

                        <p>
                            If Passenger flight is cancelled less then 24 hours, for whatever reason, and Passenger has paid in advance by Credit Card there will be no refund of the Transfer fee. <br />
                            As soon as you find out that your flight is delayed, please inform us via email info@pickuphungary.com or in phone.<br />
                            Provide the order number and the new arrival time.<br />
                            If your flight is being delayed for less than 60 minutes, the driver will monitor it and arrive by the time of landing.<br />
                            Please note that if the waiting time exceeds 1 hour, the driver may ask to pay extra for waiting.<br />
                            If a Passenger flight is diverted to another country, we are unable to make any refunds as this is beyond our control and the Passenger will be charged a “No-Show” fee.<br />
                            If the number of the flight on which the Passenger is flying is changed (usually by the airline), the Transfer will be cancelled. No refunds will be made in these cases.
                        </p>

                        <h2 class="h3">Complaints Procedure</h2>

                        <p>
                            If a Passenger wishes to make a complaint about a Supplier service this must be done so within 7 days from the date the Transfer in question took place. The complaint must be put in writing either by letter or e-mail. Telephone complaints will only deal with immediate problems at the time of taking the Transfer. If a Passenger arrives later than the time limit allowed for the Driver of the Transfer to wait for the Passenger, the Supplier is unable to investigate the Passenger complaint no matter what the reason is.<br />
                            It is important that Passenger give Supplier all the relevant facts when making a complaint as the Supplier fully investigates all complaints with our Suppliers.<br />
                            We reserve the right to take up to 180 days to resolve the complaint where there are special circumstances involved and where it is not possible to find out all facts in the short term – e.g. serious road accidents, etc.
                        </p>

                        <h2 class="h3">Data protection</h2>

                        <p>
                            The processing of personal data provided by the Passenger is controlled by the Supplier on the legal grounds, for the purposes and under the terms and conditions set forth in the Privacy Policy published on the Website. By sending the order, the Passenger confirms that he/she has read, understood and accepted the Privacy Policy.
                        </p>

                        <h2 class="h3">Provisions regarding the content of the Website</h2>

                        <h3 class="h4">Completeness, accuracy and up-to-date state of data</h3>

                        <p>
                            The Supplier updates the information on the available Transfers on the Website, and may change or delete it at any time. However, as the availability and terms of the Transfers are subject to change, the Supplier shall be not responsible for ensuring that the information published on the Website is complete, accurate and up to date at all times.
                        </p>

                        <h3 class="h4">Intellectual Property</h3>

                        <p>
                            All Transfer descriptions, slides, photographs, images, maps, travel guides and other material on the Website are under legal protection of copyright belonging to Supplier The “Pickup Hungary” name, logo and all related product and service names, designs and slogans, the trademarks or service marks are owned by Supplier. All other names, logos and all related product and service marks, designs and slogans used by other service providers on the Website are their own intellectual property.
                        </p>

                        <h3 class="h4">Promotions</h3>

                        <p>
                            Promotional or discounted offers on the Website are provided at the discretion of the Supplier. All offers are subject to availability and may be withdrawn at any time.
                        </p>

                        <h3 class="h4">Third Party</h3>

                        <p>
                            Reference on the Website to any products, services, processes, trade names, trademarks or other information about third parties does not imply or constitute an endorsement, sponsorship or recommendation by Supplier. Links to other sites not operated or controlled by Supplier are solely for the convenience of the Passenger. Supplier is not responsible for any products, services, processes, trade names, trademarks or other information provided by third parties.
                        </p>

                        <h3 class="h4">Limitation of liabilities</h3>

                        <p>
                            Supplier is not responsible for:<br />
                            a) the continuous and error-free operation of the Website;<br />
                            b) the suitability of the information, products and services on the Website for any purpose;<br />
                            c) any direct or indirect loss resulting from the use of the information on the Website.
                        </p>

                        <h2 class="h3">Other provisions</h2>

                        <h3 class="h4">Notifications</h3>

                        <p>
                            The Passenger acknowledges as communicated in writing the documents sent to the e-mail address provided by the Passenger and undertakes to secure the receipt of documents sent to the e-mail address specified until the termination of the contractual relationship.
                            In case of electronic correspondence between the parties, electronic documents and information stored on each party's computer system under reasonable security conditions shall constitute mutually accepted evidence of communication between the parties and of the conclusion and performance of the contract.
                        </p>

                        <h3 class="h4">Complaint handling, settlement of disputes</h3>

                        <p>
                            Complaints are handled by Supplier, with the same mailing address. Complaints can also be submitted via electronic mail via the following e-mail address: info@pickuphungary.com. If the Passenger has attempted to resolve his complaint with Supplier but has failed, then the Passenger may initiate proceedings with the competent arbitration body of the Passenger's domicile. The seat of the Conciliation Board of Budapest: 1016 Budapest, Krisztina krt. 99. III. em. 310., mailing address: 1253 Budapest, Pf.: 10., e-mail address: bekelteto.testulet@bkik.hu. In the event of the violation of the consumer protection rules Passengers may primarily contact to the territorially competent local district offices with their complaints. (http://jarasinfo.gov.hu).
                        </p>

                        <h3 class="h4">Third party rights</h3>

                        <p>
                            Unless otherwise stated in this GTC, no person other than the Passenger will be able to pursue any claim against the Supplier.
                        </p>

                        <h3 class="h4">Sevarability</h3>

                        <p>
                            If any provision of this GTC is void or becomes unenforceable, this will not affect the validity or enforceability of any of the other provisions thereof.
                        </p>

                        <h3 class="h4">Modification of this GTC</h3>

                        <p>
                            The Supplier entitled to modify this GTC at any time. The revised GTC shall enter into effect after its publication on the Website.
                        </p>

                        <h3 class="h4">Applicable law and jurisdiction</h3>

                        <p>
                            Matters not regulated in this GTC shall be governed by the Hungarian Law.
                            In the event of any dispute, the Hungarian Courts will have exclusive jurisdiction to deal with these issues.
                        </p>

                        <h2 class="h3">Prohibited uses</h2>

                        <p>
                            You may use our site only for lawful purposes. You may not use our site:<br />
    
                            In any way that breaches any applicable local, national or international law or regulation.<br />
                            In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.<br />
                            For the purpose of harming or attempting to harm minors in any way.<br />
                            To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards.<br />
                            To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).<br />
                            To knowingly transmit any data, send or upload any material that contains viruses, trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.<br />
                            You also agree:<br />
                            Not to reproduce, duplicate, copy or re-sell any part of our site.<br />
                            Not to access without authority, interfere with, damage or disrupt:<br />
                            any part of our site;<br />
                            any equipment or network on which our site is stored;<br />
                            any software used in the provision of our site; or<br />
                            any equipment or network or software owned or used by any third party.<br />
                        </p>

                        <p>1st of June, Budapest</p>
                        <p>Spa Thermal Travel Ltd.</p>



                </div>
            </div>
        )
    }
}