import React, { Component } from "react";
import OurFleetComponent from "../../components/OurFleetComponent";
import { NavLink} from 'react-router-dom';

import {Helmet} from "react-helmet";
import PickupServiceSteps from "../../components/PickupServiceSteps";

export default class AirportTransfer extends Component {
    render() {
        return (
            <div className="LandingPage content">

                <Helmet>
                    <title>Premium Business Transfer Services in Hungary - Book Online</title>
                    <meta
                        name="description"
                        content="Book limousine services in Hungary. Private and VIP transfer services accompanied by executive and smart drivers for individuals and group transportation"
                    />

                    <meta
                        name="keywords"
                        content="Luxury Limousine service hungary, Business travel hungary, Business car hungary, Executive drivers in Budapest, VIP transfer services hungary, Limousine Service Budapest, Executive vehicles budapest, high quality airport transfer service budapest, Budapest limousine, full-service deluxe ground transportation budapest"
                    />
                </Helmet>

                <div id="LandingPage__content">
                    <div class="mt-4 mb-5"></div>

                    <div className="container">
                        <h1 className="h2 mb-4"><i class="fa-duotone fa-user-tie me-2 text-primary"></i> Premium Business Transfer Services in Hungary</h1>

                        <p>
                            Book limousine services in Hungary. Private and VIP transfer services accompanied by executive and smart drivers for individuals and group transportation.
                        </p>

                        <p>
                            Our airport VIP Meet and Greet Service ensures a comfortable, relaxed trip, complete with your own local personal assistant to take care of your every need.
                        </p>

                        <div className="row mt-5">
                            <div className="col-12 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title h4">Meet and Greet Airport Service</h3>
                                        <p className="card-text">
                                            Private Transfer. Meet at and greet at the gate. Sign labeled with your name.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title h4">High Class & Smart Drivers</h3>
                                        <p className="card-text">
                                            Professional drivers. Luxury and elegance. Complete comfort.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 mt-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title h4">Safe & Clean</h3>
                                        <p className="card-text">
                                            European Union standards. Verified drivers. Cleaned after every use. Safe.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 mt-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title h4">Convenient</h3>
                                        <p className="card-text">
                                            Fast and hassle free booking. Money back guarantee. 24/7 support.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <PickupServiceSteps />

                        <div className="pt-5 pb-5 mt-5 text-center">
                            <p className="h5">
                                <b>Risk free booking. Cancel up to 24 hours before your travel date.</b>
                            </p>
                            <NavLink to="/booking" className="btn btn-lg btn-primary mt-5">Book Now</NavLink>
                        </div>

                    </div>

                    


                    <div className="LandingPage__our__fleet pt-5 pb-5 bg-gray">
                        <div className="container">
                            <OurFleetComponent />
                        </div>
                    </div>
                    
                            
                </div>
            </div>
        );
    }
}