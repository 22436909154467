import React, { Component } from "react";
import OurFleetComponent from "../../components/OurFleetComponent";
import { NavLink} from 'react-router-dom';

import {Helmet} from "react-helmet";
import PickupServiceSteps from "../../components/PickupServiceSteps";

export default class AirportTransfer extends Component {
    render() {
        return (
            <div className="LandingPage content">

                <Helmet>
                    <title>Budapest Airport Transfer - Best Price Guarantee</title>
                    <meta
                        name="description"
                        content="Book private transfer from Budapest Airport to city center, hotel, as well as other destinations. Pickup Hungary provides a cheap and reliable way for you to get from A to B. Meet & Greet service, clean vehicles. Book for individuals as well as groups, fast online."
                    />

                    <meta
                        name="keywords"
                        content="Book Budapest transfer, book hungary transfer, Taxi  Budapest, taxi budapest Airport, Hungary taxi, executive Budapest airport transfer, limousine service budapest, limousine service hungary, taxi pre-order budapest airport, transfer from budapest airport, taxi from budapest airport, how to get from budapest airport"
                    />
                </Helmet>

                <div id="LandingPage__content">
                    <div class="mt-4 mb-5"></div>

                    <div className="container">
                        <h1 className="h2 mb-4"><i class="fa fa-duotone text-primary fa-plane me-2"></i> Budapest Airport Transfer</h1>

                        <p>
                            Private transfer from Budapest Liszt Ferenc Airport to more than 50 destinations, 
                            with <b>the lowest price guaranteed</b> for up to 49 passengers.
                        </p>

                        <p>
                            Children friendly service.
                        </p>

                        <div className="row mt-5">
                            <div className="col-12 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title h4">Meet and Greet Service</h3>
                                        <p className="card-text">
                                            Private Transfer. Meet at and greet at the gate. Sign labeled with your name.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title h4">Save Money & Time</h3>
                                        <p className="card-text">
                                            Fixed rates. No booking & hidden fees. No booking fees. No credit card surcharges.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 mt-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title h4">Safe & Clean</h3>
                                        <p className="card-text">
                                            European Union standards. Verified drivers. Cleaned after every use. Safe.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6 mt-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title h4">Convenient</h3>
                                        <p className="card-text">
                                            Fast and hassle free booking. Money back guarantee. 24/7 support.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                        <PickupServiceSteps />

                        <div className="pt-5 pb-5 mt-5 text-center">
                            <p className="h5">
                                <b>Risk free booking. Cancel up to 24 hours before your travel date.</b>
                            </p>
                            <NavLink to="/booking" className="btn btn-lg btn-primary mt-5">Book Now</NavLink>
                        </div>

                    </div>


                    <div className="LandingPage__our__fleet pt-5 pb-5 bg-gray">
                        <div className="container">
                            <OurFleetComponent />
                        </div>
                    </div>
                    
                            
                </div>
            </div>
        );
    }
}