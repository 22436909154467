import React, { Component } from "react";
import OurFleetComponent from "../../components/OurFleetComponent";
import { NavLink} from 'react-router-dom';

import {Helmet} from "react-helmet";

export default class AirportTransfer extends Component {
    render() {
        return (
            <div className="LandingPage content">
                <Helmet>
                    <title>Our Response to COVID-19</title>
                    <meta
                        name="description"
                        content="Due to increased concerns for safety brought on by the COVID-19 virus, Pickup Hungary has implemented additional sanitary measures and rigorous protocols to ensure the utmost safety for you. Also, we are currently paying particular attention to ensuring that the measures are effective while working hard to provide you with the highest quality of service. Pickup Hungary is closely monitoring the information shared by the World Health Organisation (WHO) to comply with the best-practice guidelines."
                    />
                </Helmet>

                <div id="LandingPage__content">
                    <div class="mt-4 mb-5"></div>

                    <div className="container">
                        <h1 className="h2 mb-4">Our COVID-19 Response</h1>
                        <h2 className="h4">Our precautionary measures against COVID-19</h2>

                        <p>
                            Due to increased concerns for safety brought on by the COVID-19 virus, 
                            PickUp Hungary has implemented additional sanitary measures and rigorous protocols to 
                            ensure the utmost safety for you. Also, we are currently paying particular attention to 
                            ensuring that the measures are effective while working hard to provide you with the highest 
                            quality of service. PickUp Hungary is closely monitoring the information shared by the 
                            World Health Organisation (WHO) to comply with the best-practice guidelines.
                        </p>

                        <p class="mt-5">
                            <b>Dear Client,</b><br /><br />
                            despite the virus, we are here for you to provide you with prompt and safe method
                            of transportation during these challenging times. Ensuring the safety of our passengers
                            is one of our key priorities during the Coronavirus outbreak. We are committed to provide
                            the safest possible environment for all of our clients, drivers and employees, therefore
                            we are doing everything that we can to ensure that adequate measures are taken to guarantee
                            our passengers’ safety.
                        </p>

                        <p class="mt-4">
                            <b>We have put in place the following guidelines:</b><br /><br />

                            All vehicles are deep-cleaned on a daily basis by all of our operators;<br /><br />

                            Additional sterilization of the high-risk areas is completed after each transfer;<br /><br />

                            Our drivers will keep a safe, min. 1.5m safety distance with the customer and will 
                            great them with a smile instead of a handshake;<br /><br />

                            Physical distancing will be maintained between the driver and passengers 
                            during all times, even in smaller vehicles. Passengers will be asked to sit 
                            in the rear seats only. (maximum of 2 passengers /row) No passengers will be 
                            allowed to travel in the front passenger seat next to the driver;<br /><br />

                            To ensure the safety of our passengers all of our drivers are carefully 
                            monitoring any signs or symptoms of the virus and are instructed to 
                            report any symptoms immediately. Drivers with any sign of illness symptoms are 
                            immediately sent home to recover.
                        </p>

                        <p class="mt-4">
                            <b>BUSINESS and PREMIUM class vehicles</b><br /><br />

                            All vehicles are set up with a protective shield, to ensure safe distance is 
                            maintained between the passengers and the driver at all times;<br /><br />

                            Comprehensive sterilization and deep-clean completed on every vehicle before and after each run;<br /><br />

                            Door handles, vehicle interiors including rest arm, displays, radio, and 
                            ventilation are sanitized between services;<br /><br />

                            All the reading material, such as magazines and newspapers have been temporarily removed from all vehicles.
                        </p>

                        <div className="pt-5 pb-5 mt-5 text-center">
                            <p className="h5">
                                <b>Risk free booking. Cancel up to 24 hours before your travel date.</b>
                            </p>
                            <NavLink to="/booking" className="btn btn-lg btn-primary mt-5">Book Now</NavLink>
                        </div>

                    </div>

                    <div className="LandingPage__our__fleet pt-5 pb-5 bg-gray">
                        <div className="container">
                            <OurFleetComponent />
                        </div>
                    </div>
                            
                </div>
            </div>
        );
    }
}