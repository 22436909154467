import React, { useState, useRef, useEffect } from "react";
import { Form, Popover, Overlay } from "react-bootstrap";
import PlacesAutocomplete from "react-places-autocomplete";

export default function InputGroup({ draft, handleChange, handleAutocompleteSelect, errors, passengers }) {
    const [showFrom, setShowFrom] = useState(false);
    const [showTo, setShowTo] = useState(false);
    const targetFrom = useRef(null);
    const targetTo = useRef(null);

    const handleClickOutside = (event) => {
        if (targetFrom.current && !targetFrom.current.contains(event.target)) {
            setShowFrom(false);
        }
        if (targetTo.current && !targetTo.current.contains(event.target)) {
            setShowTo(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const renderSuggestions = (suggestions, loading, getSuggestionItemProps) => (
        <Popover id="popover-suggestions" className="BookingSearchSection__form__suggestions">
            <Popover.Body>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, key) => {
                    const className = suggestion.active
                        ? 'BookingSearchSection__form__suggestions__item--active'
                        : 'BookingSearchSection__form__suggestions__item';
                    return (
                        <div
                            key={key}
                            {...getSuggestionItemProps(suggestion, { className })}
                        >
                            <span>{suggestion.description}</span>
                        </div>
                    );
                })}
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <PlacesAutocomplete
                value={draft.destination_from}
                onChange={(value) => handleChange({ target: { id: 'draft.destination_from', value } })}
                onSelect={(address) => handleAutocompleteSelect(address, 'from')}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <>
                        <label htmlFor="BookingSearchSection__form__address_from" className="sr-only">Pick-up Location...</label>
                        <>
                            <Form.Control
                                ref={targetFrom}
                                {...getInputProps({
                                    placeholder: 'From (airport, address or train station)',
                                    isInvalid: errors.destination_from,
                                    id: 'BookingSearchSection__form__address_from',
                                    onFocus: () => setShowFrom(true)
                                })}
                            />
                            <Overlay target={targetFrom.current} show={showFrom && suggestions.length > 0} placement="bottom-start">
                                {renderSuggestions(suggestions, loading, getSuggestionItemProps)}
                            </Overlay>
                        </>
                    </>
                )}
            </PlacesAutocomplete>
            <PlacesAutocomplete
                value={draft.destination_to}
                onChange={(value) => handleChange({ target: { id: 'draft.destination_to', value } })}
                onSelect={(address) => handleAutocompleteSelect(address, 'to')}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <>
                        <label htmlFor="BookingSearchSection__form__address_to" className="sr-only">Pick-up Location...</label>
                        <>
                            <Form.Control
                                ref={targetTo}
                                {...getInputProps({
                                    placeholder: 'To (airport, address or train station)',
                                    isInvalid: errors.destination_to,
                                    id: 'BookingSearchSection__form__address_to',
                                    onFocus: () => setShowTo(true)
                                })}
                            />
                            <Overlay target={targetTo.current} show={showTo && suggestions.length > 0} placement="bottom-start">
                                {renderSuggestions(suggestions, loading, getSuggestionItemProps)}
                            </Overlay>
                        </>
                    </>
                )}
            </PlacesAutocomplete>

            {passengers && <>
                <div className="d-none d-lg-block input-group-text">
                    <i className="fa fa-light fa-users"></i>
                </div>

                <Form.Control as="select"
                    id="draft.passengers"
                    onChange={handleChange}
                    isInvalid={errors.passengers}
                >
                    <option value="">Number of passengers</option>
                    {passengers().map((value) => (
                        <option 
                            key={value} 
                            value={value}
                            selected={draft.passengers === value ? true : false}
                        >
                            {value} passenger{value > 1 ? "s" : ""}
                        </option>
                    ))}
                </Form.Control> 
            </>}
        </>
    );
}
