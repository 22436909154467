import React from "react";

import BookingPageSummaryComponent from '../../components/BookingPageSummaryComponent';

export const BookingPageStep4Template = ({goToStep, common, step4}) => (
    <div className="BookingPage__box BookingPage__step4">
        <div className="BookingPage__box__content">
            <BookingPageSummaryComponent common={common} />

            <p>
                Now we are redirecting you to our payment provider to safely check-out. Please wait...<br />
                <small>If nothing happens, please click <a href={step4.payment_link}>here</a>.</small>
            </p>

            <p className="mt-4">
                <img 
                    src="https://docs.barion.com/images/6/69/Barion-card-payment-mark-2017-400px.png" 
                    className="img-fluid" 
                    alt="Barion's, our payment solution partner's logo"
                />
            </p>
            

            <div className="mt-5"></div>
                    
            <div className="row">
                <div className="col-3">
                    <button type="button" onClick={() => goToStep(3)} className="btn btn-dark">Back to Preview</button>
                </div>
            </div>

        </div>
    </div>
);