import React from "react";

import BookingPageSummaryComponent from '../../components/BookingPageSummaryComponent';

export const BookingPageStep3Template = ({step2, goToStep, common, goToPayment}) => (
    <div className="BookingPage__box BookingPage__step3">
        <div className="BookingPage__box__content">
            <BookingPageSummaryComponent common={common} />

            <div className="row">
                <div className="col-12">
                    <iframe 
                        width="100%"
                        height="300px"
                        frameborder="0"
                        title="Route on map"
                        src={"https://www.google.com/maps/embed/v1/directions?origin=" + common.from_lat + ',' + common.from_lng + "&destination=" + common.to_lat + ',' + common.to_lng + "&key=AIzaSyDlzhzSkqz-jMVN6ShODh67Pjt1jbKIieo"} 
                        allowfullscreen></iframe>
                </div>

                <div className="col-12 mt-4">

                    <div className="row">

                        <div className="col-12 mt-3 mb-3">
                            <h4 className="fw-bold">Passenger Details</h4>
                        </div>

                        <div className="col-md-6 col-12">
                            <p class="fw-bold">Full Name</p>
                            {step2.full_name}
                        </div>

                        <div className="col-md-6 mt-3 col-12">
                            <p class="fw-bold">E-mail Address</p>
                            {step2.email_address}
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-6 col-12">
                            <p class="fw-bold">Phone Number</p>
                            <p className="h6">{step2.phone_extension + step2.phone_number}</p>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col-12 mb-3 mt-3">
                            <h4 className="fw-bold">Journey</h4>
                        </div>

                        <div className="col-md-6 col-12">
                            <p class="fw-bold">Date and Time</p>
                            <p>
                                {step2.date}<br />
                                {step2.time}
                            </p>
                        </div>

                        {common.return_journey_checkbox ? <div className="col-md-6 col-12">
                            <p class="fw-bold">Return Date and Time</p>
                            <p>
                                {step2.return_date}<br />
                                {step2.return_time}
                            </p>
                        </div> : null}
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-6 col-12">
                            <p class="fw-bold">Pick-up Location</p>
                            <p>{common.destination_from}</p>
                        </div>

                        <div className="col-md-6 col-12">
                            <p class="fw-bold">Drop Off Location</p>
                            <p>{common.destination_to}</p>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-6 col-12">
                            <p class="fw-bold">Passengers</p>
                            <p>
                                {step2.adults} adults<br />
                                {step2.infant_carrier} infant carrier<br />
                                {step2.convertible_seat} convertible seats<br />
                                {step2.booster_seat} booster seats
                            </p>
                        </div>

                        <div className="col-md-6 col-12">
                            <p class="fw-bold">Additional Information</p>
                            <p>{step2.flight_number_checkbox ? step2.flight_number : null}</p>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-6 col-12">
                            <p className="fw-bold">Comments</p>
                            <p>{step2.comments}</p>
                        </div>

                        <div className="col-md-6 col-12">
                            <p className="fw-bold">Price</p>
                            <p><i class="fas fa-tags"></i> {common.price} EUR {common.discount_code ? <span>(including {common.discount_percentage}% off)</span> : null}</p>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-12 mb-3 mt-3">
                            <h4 className="fw-bold">Billing</h4>
                        </div>

                        <div className="col-md-6 col-12">
                            <p class="fw-bold">Billing Name</p>
                            <p>{step2.billing_name}</p>
                        </div>

                        <div className="col-md-6 col-12">
                            <p class="fw-bold">Billing Address</p>
                            <p>
                                {step2.billing_address} <br />
                                {step2.billing_city} <br />
                                {step2.billing_postcode} <br />
                                {step2.billing_country} <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-5"></div>
                    
            <div className="row">
                <div className="col-3">
                    <button type="button" onClick={() => goToStep(2)} className="btn btn-dark">Edit Details</button>
                </div>
                
                <div className="col-9 text-end">
                    <button type="button" onClick={goToPayment} className="btn btn-primary btn-lg">Pay</button>
                </div>
            </div>
        </div>
    </div>
);